/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { Price } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Chip } from "@mui/material";
import OFBCardContact from "./sub-components/OFBCardContact";
import OFBCardImage from "./sub-components/OFBCardImage";
import OFBCardLocation from "./sub-components/OFBCardLocation";
import OFBCardTitle from "./sub-components/OFBCardTitle";
import OFBCardType from "./sub-components/OFBCardType";

/* Styled Components */

const OFBCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "23.125rem",
	margin: "1rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "100%",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "100%",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "100%",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "100%",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "23.125rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "23.125rem",
	},
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	[theme.breakpoints.down(413)]: {
		padding: "0.75rem 1.25rem 1rem 1.25rem",
	},
}));

const TitleAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "1rem 1.25rem 0rem 1rem",
	width: "100%",
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	height: "1.75rem",
	// fontWeight: 500,
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	backgroundColor: "#ffc5c5",
	color: "#212121",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const OFBCardPublishPreview = ({
	property_category,
	property_status,
	transaction_type,
	price,
	property_type,
	title,
	images,
	location,
}: {
	property_category: string;
	property_status: Array<string>;
	transaction_type: string;
	price: Price;
	property_type: Array<string>;
	title: string;
	images: string;
	location: string;
}) => {
	// const [imgPreview, setImgPreview] = React.useState<string>("");

	// lets select the image with priority 0 if 0 not existing then select the first image

	// React.useEffect(() => {
	// 	if (images && images.length > 0) {
	// 		const image = images.find((img) => img.priority === 0);
	// 		if (image) {
	// 			setImgPreview(image.caption);
	// 		} else if (images.length === 0) {
	// 			setImgPreview("");
	// 		} else {
	// 			setImgPreview(images[0].caption);
	// 		}
	// 	} else {
	// 		setImgPreview("");
	// 	}
	// }, [images]);

	return (
		<React.Fragment>
			<OFBCardContainer source={""}>
				{/* Image */}

				<OFBCardImage images={images} />

				<TitleAndTransactionContainer>
					{/* Title */}

					<OFBCardTitle title={title !== "" || undefined ? title : "Unknown Title"} />

					{/* Transaction Type Chip */}

					<CustomChip label="OFB" />
				</TitleAndTransactionContainer>

				<OFBCardInfoContainer>
					<CardRow>
						{/* OFB Type */}
						{/* {property_type?.length > 0 && ( */}
						<OFBCardType propertyType={property_type?.length > 0 ? property_type : [property_category]} />
						{/* )} */}
						<VerticalLine />

						{/* Location */}

						<OFBCardLocation location={location !== "" || undefined ? location : "Unknown Location"} />
					</CardRow>

					{/* Config and CTA */}

					<OFBCardContact />
				</OFBCardInfoContainer>
			</OFBCardContainer>
		</React.Fragment>
	);
};

export default OFBCardPublishPreview;
