/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../../redux-magic/state-interface";
import { Area, BHK, Price, PricePerUnit } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Link } from "@mui/material";
import OFBCardCTA from "./sub-components/OFBCardCTA";
import OFBCardImage from "./sub-components/OFBCardImage";
import OFBCardLocation from "./sub-components/OFBCardLocation";
import OFBCardTitle from "./sub-components/OFBCardTitle";
import OFBCardType from "./sub-components/OFBCardType";

/* Library Function Imports */

import { useRouter } from "next/router";
import PrepareConfigString from "../../../../lib/PrepareConfigString";
import prepareConfigTitle from "../../../../lib/PrepareConfigTitle";

/* Styled Components */

const OFBLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const OFBCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source" && prop !== "isEditor",
})<{ source: string; isEditor: boolean }>(({ theme, source, isEditor }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "10px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "29.65rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: "6.65rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* XS breakpoint */
	[theme.breakpoints.up("xs")]: {
		width: "7.75rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: "8.65rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: "6.65rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: "6.95rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: "7.25rem",
		height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: "7.75rem",
		height: source === "business_profile_listings" && isEditor ? "12.25rem" : "10rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: "8.1rem",
		height: source === "business_profile_listings" && isEditor ? "12.25rem" : "10rem",
	},
	/*457*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		width: "8.65rem",
		height: source === "business_profile_listings" && isEditor ? "12.5rem" : "10.25rem",
	},
	/*485*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		width: "9.25rem",
		height: source === "business_profile_listings" && isEditor ? "12.75rem" : "10.5rem",
	},
	/*512*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		width: "9.8rem",
		height: source === "business_profile_listings" && isEditor ? "13.25rem" : "11rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: "7.3rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	/*570*/
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: "7.77rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "7.5rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	/*642*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: "7.95rem",
		height: source === "business_profile_listings" && isEditor ? "12rem" : "9.75rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		width: "8.6rem",
		height: source === "business_profile_listings" && isEditor ? "13.75rem" : "11.5rem",
	},
	/*726*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 126)]: {
		width: "9.25rem",
		height: source === "business_profile_listings" && isEditor ? "13.75rem" : "11.5rem",
	},
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "9.9rem",
		height: source === "business_profile_listings" && isEditor ? "14.25rem" : "12rem",
	},
	/*ipad Air - 820*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "10.65rem",
		height: source === "business_profile_listings" && isEditor ? "15rem" : "12.75rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		width: "11.35rem",
		height: source === "business_profile_listings" && isEditor ? "16rem" : "13.75rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		width: "12.15rem",
		height: source === "business_profile_listings" && isEditor ? "17rem" : "14.75rem",
	},
	/*960*/
	[theme.breakpoints.up("md")]: {
		width: "12.4rem",
		height: source === "business_profile_listings" && isEditor ? "17rem" : "14.75rem",
	},
	/*iPadPro - 1024*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "13.3rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/*1107*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		width: "14.5rem",
		height: source === "business_profile_listings" && isEditor ? "18rem" : "15.75rem",
	},
	/*1190 ~~Desktop Layout Start~~*/
	[theme.breakpoints.up("md1190")]: {
		width: "15.65rem",
		height: source === "business_profile_listings" && isEditor ? "18.75rem" : "16.5rem",
	},
	/*720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "12.45rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "13.2rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "12.8rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "11.75rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "12rem",
		height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
}));

const OFBCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "0.35rem 0.35rem 0.35rem 0.35rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

// const CustomChip = styled(Chip, {
// 	shouldForwardProp: (prop) => prop !== "transaction_type",
// })<{ transaction_type: string }>(({ theme, transaction_type }) => ({
// 	height: "1.125rem",
// 	padding: "0rem",
// 	// fontWeight: 500,
// 	fontSize: "0.625rem",
// 	letterSpacing: "0.02857em",
// 	borderRadius: "8px",
// 	backgroundColor:
// 		transaction_type === "buy"
// 			? "#b7efc5"
// 			: transaction_type === "rent"
// 				? "#ffc6ac"
// 				: transaction_type === "resale"
// 					? "#caf0f8"
// 					: "#b7efc5",
// 	color:
// 		transaction_type === "buy"
// 			? "#1e4620"
// 			: transaction_type === "rent"
// 				? "#4c2708"
// 				: transaction_type === "resale"
// 					? "#212121"
// 					: "#1e4620",
// }));

const OFBCard = ({
	id,
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	transaction_type,
	property_type,
	ofb_category,
	title,
	images,
	property_status,
	possession_date,
	location,
	published_status,
	developer,
	price,
	price_per_unit,
	area,
	bhk,
	floors,
	no_of_seats,
	saved_content,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
	show_contact_details,
	source,
	search_log_id,
	bottom_beegru_points,
	boost,
}: {
	id?: string;
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	transaction_type: string;
	property_type: Array<string>;
	ofb_category: string;
	title: string;
	images: string;
	property_status: Array<string>;
	possession_date: string;
	published_status: string;
	location: string;
	developer: string;
	price: Price;
	price_per_unit: PricePerUnit;
	area: Area;
	bhk: BHK;
	floors: BHK;
	no_of_seats: BHK;
	saved_content: Array<any>;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean | undefined;
	boosting: StateInterface["property"]["boost_information"];
	preview?: boolean;
	show_contact_details: boolean;
	source?: string;
	search_log_id?: string;
	bottom_beegru_points?: StateInterface["bottom_subscribe_buy_beegru_points"];
	boost?: StateInterface["boost"];
}) => {
	const router = useRouter();

	let BoostingCheck = boosting?.active ? true : false;

	return (
		<React.Fragment>
			<OFBLink
				href={`/${router.locale}/properties/${slug}?search_log_id=${search_log_id}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
				title={title}
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<OFBCardContainer
					source={source ? source : ""}
					isEditor={isEditor !== undefined ? isEditor : false}
					id={id}
				>
					{/* Image */}

					<OFBCardImage
						_id={_id}
						slug={slug}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						created_by={created_by}
						assigned_to={assigned_to}
						isEditor={isEditor}
						boosting={boosting}
						transaction_type={transaction_type}
					/>

					{/* Title */}

					<OFBCardInfoContainer>
						<PriceAndTransactionContainer>
							{/* Transaction Type Chip */}

							{/* <CustomChip
								label={
									transaction_type && transaction_type === "buy"
										? "Sale"
										: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
								}
								transaction_type={transaction_type}
							/> */}
						</PriceAndTransactionContainer>

						<OFBCardTitle
							loading={loading}
							title={title !== "" || undefined ? title : "Unknown Title"}
						/>

						{/* Location */}

						<OFBCardLocation
							loading={loading}
							location={location !== "" || undefined ? location : "Unknown Location"}
						/>

						{/* OFB Type */}

						<OFBCardType
							loading={loading}
							propertyType={ofb_category ? ofb_category : ""}
						/>

						{/* Config  */}

						{/* <OFBCardConfigAndContact
							loading={loading}
							config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
						/> */}

						{/* CTA  */}

						{source === "business_profile_listings" && isEditor ? (
							<OFBCardCTA
								loading={loading}
								developer={developer !== "" || undefined ? developer : created_by.business_profile_id.title}
								config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
								card_title={prepareConfigTitle(
									transaction_type as string,
									property_type as Array<string>,
									router.locale ? (router.locale as string) : "en-IN",
								)}
								published_status={published_status}
								dispatch={dispatch}
								session={session}
								slug={slug}
								title={title}
								created_by={created_by}
								show_contact_details={show_contact_details}
								isEditor={isEditor}
								isBoosted={BoostingCheck}
								bottom_beegru_points={bottom_beegru_points}
								boost={boost}
							/>
						) : null}
					</OFBCardInfoContainer>
				</OFBCardContainer>
			</OFBLink>
		</React.Fragment>
	);
};

export default OFBCard;
