/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Component Imports */

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { IconButton, Link, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	flexWrap: "wrap",
	width: "100%",
	height: "100%",
	margin: "0.25rem 0rem 1rem 0rem",
}));

const AboutUsSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "35%",
	[theme.breakpoints.down("lg")]: {
		margin: "0rem 0rem 1rem 0rem",
		width: "100%",
	},
}));

const CompanySection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "15%",
	[theme.breakpoints.down("lg")]: {
		width: "20%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "45%",
	},
}));

const OurServicesSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "10%",
	[theme.breakpoints.down("lg")]: {
		width: "20%",
	},
	[theme.breakpoints.down("sm")]: {
		width: "40%",
	},
}));

const GetInTouchSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "27%",
	[theme.breakpoints.down("lg")]: {
		width: "45%",
	},
	[theme.breakpoints.down("sm")]: {
		flexDirection: "row",
		justifyContent: "space-between",
		margin: "1rem 0rem 0rem 0rem",
		width: "100%",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	margin: "0rem 0rem 0.5rem 0rem",
	fontWeight: 500,
	lineHeight: "1.25rem",
	width: "100%",
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 400,
	color: theme.palette.mode === "dark" ? "#fff" : "#666666",
}));

const List = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
}));

const CustomLink = styled(Link)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
	fontWeight: 400,
	color: theme.palette.mode === "dark" ? "#fff" : "#666666",
	textDecoration: "none",
	"@media (pointer: fine)": {
		"&:hover": {
			// textDecoration: "underline",
			color: theme.palette.primary.main,
		},
	},
}));

const FooterLinks = (props: any) => {
	const router = useRouter();

	const theme = useTheme();

	const [windowWidth, setWindowWidth] = React.useState(0);

	React.useEffect(() => {
		if (typeof window !== "undefined") {
			setWindowWidth(window.innerWidth);

			const handleResize = () => {
				setWindowWidth(window.innerWidth);
			};

			window.addEventListener("resize", handleResize);

			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
	}, []);

	return (
		<React.Fragment>
			<Container>
				{/* About us */}

				<AboutUsSection>
					{/* <Title variant="body1">Beegru gets properties</Title> */}
					<Text variant="body2">
						{router.locale === "ar-AE"
							? "بيجرو تحصل على العقارات"
							: router.locale === "kn-IN"
								? "ನಿಮಗೆ ಸೂಕ್ತವಾದ ಆಸ್ತಿಗಳು ಬೀಗ್ರು ಬಳಿಯಿವೆ "
								: "Beegru gets properties"}
					</Text>
				</AboutUsSection>

				{/* Company */}

				<CompanySection>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
						}}
					>
						{router.locale === "ar-AE" ? "شركة" : router.locale === "kn-IN" ? "ಕಂಪನಿ" : "Company"}
					</Title>
					<List>
						<CustomLink
							href={"/" + router.locale + "/faqs"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "الأسئلة الشائعة" : router.locale === "kn-IN" ? "ಪ್ರಶ್ನೆಗಳು" : "FAQs"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/terms-of-use"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "شروط الاستخدام"
								: router.locale === "kn-IN"
									? "ಬಳಕೆಯ ನಿಯಮಗಳು"
									: "Terms of use"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/privacy"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "سياسة الخصوصية"
								: router.locale === "kn-IN"
									? "ಗೌಪ್ಯತೆ ನೀತಿ"
									: "Privacy policy"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/payment-policy"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "سياسة الدفع" : router.locale === "kn-IN" ? "ಪಾವತಿ ನೀತಿ" : "Payment policy"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/refund-and-cancellation-policy"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "سياسة الاسترجاع والإلغاء"
								: router.locale === "kn-IN"
									? "ಮರುಪಾವತಿ ಮತ್ತು ರದ್ದತಿ ನೀತಿ"
									: "Refund and cancellation policy"}
						</CustomLink>
					</List>
				</CompanySection>

				{/* Our services */}

				<OurServicesSection>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
						}}
					>
						{router.locale === "ar-AE" ? "خدماتنا" : router.locale === "kn-IN" ? "ನಮ್ಮ ಸೇವೆಗಳು" : "Our services"}
					</Title>
					<List>
						<CustomLink
							href={"/" + router.locale + "/intro?type=agent"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "وكلاء العقارات" : router.locale === "kn-IN" ? "ಏಜೆಂಟ್ಸ್" : "Agents"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/intro?type=property-seeker"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "باحثو العقارات"
								: router.locale === "kn-IN"
									? "ಆಸ್ತಿ ಹುಡುಕಾಟ"
									: "Property seekers"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/intro?type=landowner"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "مالكو الأراضي" : router.locale === "kn-IN" ? "ಭೂ ಮಾಲೀಕರು" : "Landowners"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/intro?type=developer"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE" ? "المطورون" : router.locale === "kn-IN" ? "ಡೆವೆಲೊಪರ್ಸ್" : "Developers"}
						</CustomLink>
						<CustomLink
							href={"/" + router.locale + "/intro?type=professional"}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							{router.locale === "ar-AE"
								? "محترف الخدمات"
								: router.locale === "kn-IN"
									? "ಸೇವಾ ಪೂರೈಕೆದಾರರು"
									: "Service providers"}
						</CustomLink>
					</List>
				</OurServicesSection>

				{/* Get in touch */}

				<GetInTouchSection>
					<Title
						variant="body1"
						sx={{
							fontWeight: router.locale === "ar-AE" ? 600 : 500,
						}}
					>
						{router.locale === "ar-AE" ? "تواصل معنا" : router.locale === "kn-IN" ? "ಸಂಪರ್ಕದಲ್ಲಿರಿ" : "Get in touch"}
					</Title>
					{windowWidth < 540 ? (
						<List sx={{ flexDirection: "row", justifyContent: "flex-end", gap: "1.25rem" }}>
							<IconButton>
								<CustomLink
									href="https://maps.app.goo.gl/57TYegsdMNkeyGje9"
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
								>
									<LocationOnOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</CustomLink>
							</IconButton>
							<IconButton>
								<CustomLink
									href="tel:+917829371999"
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
								>
									<PhoneOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</CustomLink>
							</IconButton>
							<IconButton>
								<CustomLink
									href="mailto:support@beegru.com"
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
								>
									<MailOutlineOutlinedIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</CustomLink>
							</IconButton>
						</List>
					) : (
						<List>
							<Text variant="body2">
								1090i, 4th Floor, 18th Cross Road, 3rd Sector, HSR Layout, Bengaluru, Karnataka 560 102
							</Text>
							<CustomLink
								href="tel:+917829371999"
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
							>
								+91 78293 71999
							</CustomLink>
							<CustomLink
								href="mailto:support@beegru.com"
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
							>
								support@beegru.com
							</CustomLink>
						</List>
					)}
				</GetInTouchSection>
			</Container>
		</React.Fragment>
	);
};

export default FooterLinks;
