/* Redux Imports */

import StateInterface from "../redux-magic/state-interface";

/* This function takes in the property data and returns the percentage of the completion of mandatory fields for the property */

const CalculatePropertyCompletionPercentage = (propertyData: StateInterface["property"]) => {
	/* Let's declare a variable for the percentage of the property completion. We'll give it a default value of 0 */

	let percentage = 0;

	/* Let's declare a variable for the total number of fields that should be filled. This number can change based on several conditions */

	let total_fields = ["transaction_type", "property_category", "property_type", "location", "price"];

	/* Now, let's declare a variable for the number of fields that have been filled. This number will keep changing as those fields are filled */

	let completed_fields = 0;

	if (propertyData && propertyData.transaction_type !== "ofb") {
		/* Now, let's check if fields are not empty. If it's not, we'll increment the completed_fields variable by 1, other wise we'll decrease completed_fields variable by 1 */

		/* Transaction Type */

		if (propertyData.transaction_type !== "") {
			completed_fields += 1;
		}

		/* Property Category */

		if (propertyData.property_category !== "") {
			completed_fields += 1;
		}

		/* Property Type */

		if (propertyData.property_type.length > 0) {
			completed_fields += 1;
		}

		/* Property Location */

		if (propertyData.location.title !== "") {
			completed_fields += 1;
		}

		/* Price */

		if (
			propertyData.price.min !== 0 &&
			propertyData.price.min !== null &&
			propertyData.price.max !== 0 &&
			propertyData.price.max !== null &&
			propertyData.price.min <= propertyData.price.max
		) {
			completed_fields += 1;
		}

		/* Availability */

		if (
			propertyData.property_type.includes("Apartment") ||
			propertyData.property_type.includes("Independent House") ||
			propertyData.property_type.includes("Villa") ||
			propertyData.property_type.includes("Plot") ||
			propertyData.property_type.includes("Co-living/PG") ||
			propertyData.property_type.includes("Row House") ||
			propertyData.property_type.includes("Builder Floor Apartment") ||
			propertyData.property_type.includes("Penthouse") ||
			propertyData.property_type.includes("Studio Apartment") ||
			propertyData.property_type.includes("Building") ||
			propertyData.property_type.includes("Office Space") ||
			propertyData.property_type.includes("Co-working") ||
			propertyData.property_type.includes("Farm House") ||
			propertyData.property_type.includes("Farm Plot") ||
			propertyData.property_type.includes("Plot (Commercial)") ||
			propertyData.property_type.includes("Factory")
		) {
			total_fields.push("availability");
			if (propertyData.availability.length > 0) {
				completed_fields += 1;
			}
		}

		/* Posession Date */

		if (
			propertyData.availability.includes("Under construction") ||
			propertyData.availability.includes("under_construction") ||
			propertyData.availability.includes("Select date") ||
			propertyData.availability.includes("select_date")
		) {
			total_fields.push("possession_date");
			if (propertyData.possession_date !== "") {
				completed_fields += 1;
			}
		}

		/* Property Age */

		if (
			propertyData.transaction_type == "resale" &&
			!propertyData.property_type.includes("Land (Residential)") &&
			!propertyData.property_type.includes("Land (Commercial)") &&
			!propertyData.property_type.includes("Land (Agricultural)") &&
			!propertyData.property_type.includes("Land (Industrial)") &&
			!propertyData.property_type.includes("Plot") &&
			!propertyData.property_type.includes("Farm Plot") &&
			!propertyData.property_type.includes("Plot (Commercial)") &&
			!propertyData.property_type.includes("Mining") &&
			!propertyData.availability.includes("Under construction") &&
			!propertyData.availability.includes("under_construction") &&
			!propertyData.availability.includes("Select date") &&
			!propertyData.availability.includes("select_date")
		) {
			total_fields.push("property_age");
			if (propertyData.property_age !== 0 && propertyData.property_age !== null) {
				completed_fields += 1;
			}
		}

		/* BHK */

		if (
			propertyData.property_type.includes("Apartment") ||
			propertyData.property_type.includes("Independent House") ||
			propertyData.property_type.includes("Villa") ||
			propertyData.property_type.includes("Co-living/PG") ||
			propertyData.property_type.includes("Row House") ||
			propertyData.property_type.includes("Builder Floor Apartment") ||
			propertyData.property_type.includes("Penthouse") ||
			propertyData.property_type.includes("Farm House")
		) {
			total_fields.push("bhk");
			if (
				propertyData.bhk.min !== 0 &&
				propertyData.bhk.min !== null &&
				propertyData.bhk.max !== 0 &&
				propertyData.bhk.max !== null &&
				propertyData.bhk.min <= propertyData.bhk.max
			) {
				completed_fields += 1;
			}
		}

		/* No. of seats */

		if (propertyData.property_type.includes("Co-working")) {
			total_fields.push("no_of_seats");
			if (propertyData.no_of_seats.max !== 0 && propertyData.no_of_seats.max !== null) {
				completed_fields += 1;
			}
		}

		/* No. of units */

		if (
			propertyData.transaction_type == "buy" &&
			(propertyData.property_type.includes("Apartment") ||
				propertyData.property_type.includes("Plot") ||
				propertyData.property_type.includes("Farm Plot") ||
				propertyData.property_type.includes("Plot (Commercial)") ||
				propertyData.property_type.includes("Villa") ||
				propertyData.property_type.includes("Row House") ||
				propertyData.property_type.includes("Builder Floor Apartment") ||
				propertyData.property_type.includes("Studio Apartment") ||
				propertyData.property_type.includes("Penthouse"))
		) {
			total_fields.push("no_of_units");
			if (propertyData.no_of_units.max !== 0 && propertyData.no_of_units.max !== null) {
				completed_fields += 1;
			}
		}

		/* Plot Dimensions */

		if (
			propertyData.property_type.includes("Plot") ||
			propertyData.property_type.includes("Farm Plot") ||
			propertyData.property_type.includes("Plot (Commercial)")
		) {
			total_fields.push("plot_dimensions");
			if (propertyData.plot_dimensions.length > 0) {
				completed_fields += 1;
			}
		}

		/* Land Area */

		if (
			propertyData.transaction_type == "buy" ||
			(propertyData.transaction_type == "rent" &&
				(propertyData.property_type.includes("Land (Residential)") ||
					propertyData.property_type.includes("Land (Commercial)") ||
					propertyData.property_type.includes("Land (Agricultural)") ||
					propertyData.property_type.includes("Land (Industrial)") ||
					propertyData.property_type.includes("Mining")))
		) {
			total_fields.push("land_area");
			if (propertyData.area.land_area.max.acre !== 0 && propertyData.area.land_area.max.acre !== null) {
				completed_fields += 1;
			}
		}

		/* Super Built Up Area */

		if (
			(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Independent House")) ||
			(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Co-living/PG")) ||
			(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Building")) ||
			(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Office Space")) ||
			(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Warehouse")) ||
			(propertyData.transaction_type == "buy" && propertyData.property_type.includes("Factory")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Independent House")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Apartment")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Villa")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Builder Floor Apartment")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Penthouse")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Row House")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Plot")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm Plot")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Plot (Commercial)")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Farm House")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Warehouse")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Factory")) ||
			(propertyData.transaction_type == "rent" && propertyData.property_type.includes("Building")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Independent House")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-living/PG")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Building")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Office Space")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Co-working")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Plot")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm Plot")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Plot (Commercial)")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Farm House")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Warehouse")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Apartment")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Villa")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Builder Floor Apartment")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Penthouse")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Row House")) ||
			(propertyData.transaction_type == "resale" && propertyData.property_type.includes("Factory"))
		) {
			total_fields.push("super_built_up_area");
			if (
				propertyData.area.super_built_up_area.min.ft !== 0 &&
				propertyData.area.super_built_up_area.min.ft !== null &&
				propertyData.area.super_built_up_area.max.ft !== 0 &&
				propertyData.area.super_built_up_area.max.ft !== null &&
				propertyData.area.super_built_up_area.min.ft <= propertyData.area.super_built_up_area.max.ft
			) {
				completed_fields += 1;
			}
		}

		/* Calculating the percentage */

		percentage = Math.round((completed_fields / total_fields.length) * 100);
	} else {
		/* If the transaction type is ofb, we'll give the percentage a value of 100 since there are no mandatory fields in OFB */

		percentage = 100;
	}

	return percentage;
};

export default CalculatePropertyCompletionPercentage;
