/* Common Imports */

import { styled } from "@mui/system";
import { Dayjs } from "dayjs";
import React from "react";

/* Redux Imports */

import {
	SetIsStudioThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewPropertyCategoryTypeThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewePropertyPossessionDateThunk,
} from "../../../../../redux-magic/store";

/* Component Imports */

import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";

/* Function Imports */

import NumberInWords from "../../../../../lib/NumberInWords";

/* Icon Imports */

import { useRouter } from "next/router";
import CurrencyToNumberPipe from "../../../../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../../../../lib/NumberToCurrencyPipe";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	borderRadius: "8px",
	gap: "0.5rem",
	overflow: "auto",
	paddingTop: "2rem",
	// boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.6rem",
	width: "100%",
}));

interface security_deposit {
	min: number;
	max: number;
	negotiable: boolean;
	currency: string;
}

const PropertyDetails = ({
	property_transaction_type,
	property_category_type,
	property_type,
	property_subtype,
	property_min_price,
	property_max_price,
	property_security_deposit,
	property_availability,
	property_possession_date,
	property_unit_facing,
	property_land_facing,
	property_age,
	dispatch,
	transactionTypeError,
	setTransactionTypeError,
	propertyCategoryError,
	setPropertyCategoryError,
	propertyTypeError,
	setPropertyTypeError,
	priceMinError,
	setPriceMinError,
	priceMaxError,
	setPriceMaxError,
	availabilityError,
	setAvailabilityError,
	possessionDateError,
	setPossessionDateError,
}: {
	property_transaction_type: string | undefined;
	property_category_type: string | undefined;
	property_type: Array<string> | undefined;
	property_subtype: string | undefined;
	property_min_price: number | undefined;
	property_max_price: number | undefined;
	property_security_deposit: security_deposit;
	property_availability: Array<string> | undefined;
	property_possession_date: string | Date | undefined;
	property_unit_facing: Array<string>;
	property_land_facing: Array<string>;
	property_age: number | undefined;
	dispatch: Function;
	transactionTypeError: boolean;
	setTransactionTypeError: Function;
	propertyCategoryError: boolean;
	setPropertyCategoryError: Function;
	propertyTypeError: boolean;
	setPropertyTypeError: Function;
	priceMinError: boolean;
	setPriceMinError: Function;
	priceMaxError: boolean;
	setPriceMaxError: Function;
	availabilityError: boolean;
	setAvailabilityError: Function;
	possessionDateError: boolean;
	setPossessionDateError: Function;
}) => {
	const router = React.useRef(useRouter()).current;

	const theme = useTheme();

	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(priceMinError);

	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(priceMaxError);

	const [errorPropertyAge, setErrorPropertyAge] = React.useState<boolean>(false);

	const propertyType = property_type ? property_type[0] : "";

	const propertyMaxPrice = property_max_price ? property_max_price : 0;

	const propertyMinPrice = property_min_price ? property_min_price : 0;

	const scrollTo = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	React.useEffect(() => {
		if (transactionTypeError) {
			scrollTo("transaction-type");
		} else if (propertyCategoryError) {
			scrollTo("property-category");
		} else if (propertyTypeError) {
			scrollTo("property-type");
		} else if (priceMinError || priceMaxError) {
			scrollTo("property-price");
		} else if (availabilityError) {
			scrollTo("property-availability");
		} else if (possessionDateError) {
			scrollTo("possession-date");
		}
	}, [
		transactionTypeError,
		propertyCategoryError,
		propertyTypeError,
		priceMinError,
		priceMaxError,
		availabilityError,
		possessionDateError,
	]);

	/* Functions */

	const handleChangePropertyType = async (event: SelectChangeEvent<typeof property_type>) => {
		const {
			target: { value },
		} = event;
		if (value === "Studio Apartment") {
			await dispatch(SetIsStudioThunk(true));
			dispatch(setCreateNewPropertyTypeThunk(["Apartment"]));
			dispatch(setCreateNewPropertySubTypeThunk(""));
			dispatch(setCreateNewPropertyMinPriceThunk(0));
			dispatch(setCreateNewPropertyMaxPriceThunk(0));
			dispatch(setCreateNewPropertySecurityDepositThunk(0));
			dispatch(setCreateNewPropertyAvailabiltyThunk([]));
			dispatch(setCreateNewePropertyPossessionDateThunk(""));
			dispatch(setCreateNewPropertyAgeThunk(0));
			dispatch(setCreateNewPropertyUnitFacingThunk([]));
			dispatch(setCreateNewPropertyLandFacingThunk([]));
			dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		} else {
			await dispatch(SetIsStudioThunk(false));
			dispatch(
				setCreateNewPropertyTypeThunk(
					// On autofill we get a stringified value.
					typeof value === "string" ? (value.split(",") as Array<string>) : (value as Array<string>),
				),
			);
			dispatch(setCreateNewPropertySubTypeThunk(""));
			dispatch(setCreateNewPropertyMinPriceThunk(0));
			dispatch(setCreateNewPropertyMaxPriceThunk(0));
			dispatch(setCreateNewPropertySecurityDepositThunk(0));
			dispatch(setCreateNewPropertyAvailabiltyThunk([]));
			dispatch(setCreateNewePropertyPossessionDateThunk(""));
			dispatch(setCreateNewPropertyAgeThunk(0));
			dispatch(setCreateNewPropertyUnitFacingThunk([]));
			dispatch(setCreateNewPropertyLandFacingThunk([]));
			dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		}
		setPropertyTypeError(false);
	};

	const handleChangeAvailability = (event: SelectChangeEvent<typeof property_availability>) => {
		const {
			target: { value },
		} = event;

		if (value === "immediately" || value === "ready_to_move_in" || value === "ready_for_construction") {
			dispatch(setCreateNewePropertyPossessionDateThunk(new Date().toISOString().slice(0, 10)));
			dispatch(
				setCreateNewPropertyAvailabiltyThunk(
					// On autofill we get a stringified value.
					typeof value === "string" ? value.split(",") : value,
				),
			);
			dispatch(setCreateNewePropertyPossessionDateThunk(""));
		} else {
			dispatch(
				setCreateNewPropertyAvailabiltyThunk(
					// On autofill we get a stringified value.
					typeof value === "string" ? (value.split(",") as Array<string>) : (value as Array<string>),
				),
			);
		}
	};

	const handleChangeUnitFacing = (event: SelectChangeEvent<typeof property_unit_facing>) => {
		const {
			target: { value },
		} = event;
		dispatch(
			setCreateNewPropertyUnitFacingThunk(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value,
			),
		);
	};

	const handleChangeLandFacing = (event: SelectChangeEvent<typeof property_land_facing>) => {
		const {
			target: { value },
		} = event;
		dispatch(
			setCreateNewPropertyLandFacingThunk(
				// On autofill we get a stringified value.
				typeof value === "string" ? value.split(",") : value,
			),
		);
	};

	const pTypes: Array<string> = [];
	const AvailItems: Array<string> = [];
	const subtype_options: Array<string> = [];
	const localLang = {
		transactionType:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವ್ಯವಹಾರ ಪ್ರಕಾರ*" : "Transaction type*",
		sale: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಮಾರಾಟ" : "Sale",
		rent: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬಾಡಿಗೆ" : "Rent",
		resale: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಮರಳಿ ಮಾರು" : "Resale",
		pleaseSelectTransactionType:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ದಯವಿಟ್ಟು ವ್ಯವಹಾರ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
					: "Please select a transaction type",
		property: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ರಾಪರ್ಟಿ" : "Property",
		ofb: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಓಎಫ್ಬಿ" : "OFB",
		category: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? " ವರ್ಗ*" : " category*",
		publicInfrastructure:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಸಾರ್ವಜನಿಕ ಇನ್ಫ್ರಾಸ್ಟ್ರಕ್ಚರ್"
					: "Public Infrastructure",
		jointDevelopment:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಯೋಜನೆ ಅಭಿವೃದ್ಧಿ" : "Joint Development",
		jointVenture: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಯೋಜನೆ ವೆಂಚರ್" : "Joint Venture",
		residential: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವಾಸಿಕ" : "Residential",
		commercial: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವಾಣಿಜ್ಯಿಕ" : "Commercial",
		agricultural: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕೃಷಿ" : "Agricultural",
		warehousing: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಗೋದಾಮಿಕೆ" : "Warehousing",
		industrial: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕೈಗಾರಿಕ" : "Industrial",
		pleseSelectCategory:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ದಯವಿಟ್ಟು ಆಸ್ತಿ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
					: "Please select a property category",
		type: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? " ಪ್ರಕಾರ*" : " Type*",
		pleaseSelectPropertyType:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ದಯವಿಟ್ಟು ಆಸ್ತಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ"
					: "Please select a property type",
		minPrice: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕನಿಷ್ಠ ಬೆಲೆ*" : "Minimum Price*",
		valueMoreTnanMaxPrice:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಗರಿಷ್ಠ ಬೆಲಿಗಿಂತ ಹೆಚ್ಚು ಇದೆ"
					: "Value is more than max price",
		maxPrice: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಬೆಲೆ*" : "Maximum Price*",
		valueLessThanMinPrice:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಮೌಲ್ಯವು ಕನಿಷ್ಠ ಬೆಲಿಗಿಂತ ಕಡಿಮೆಯಿದೆ"
					: "Value is less than min price",
		price: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬೆಲೆ*" : "Price*",
		monthlyRent: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ತಿಂಗಳಿನ ಬಾಡಿಗೆ*" : "Monthly Rent*",
		enterAValidNumber:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಒಂದು ಮಾನ್ಯ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ"
					: "Enter a valid number",
		securityDeposit: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭದ್ರತಾ ಠೇವಣಿ" : "Security Deposit",
		propertyStatus: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಆಸ್ತಿ ಸ್ಥಿತಿ*" : "Property Status*",
		availableFrom: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಲಭ್ಯವಿದೆ ಇಂದಿಂದ*" : "Available from*",
		pleaseSelectPropertyStatus:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ದಯವಿಟ್ಟು ಆಸ್ತಿ ಸ್ಥಿತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ"
					: "Please select property status",
		possessionDate:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವಾಮಿತ್ವ ದಿನಾಂಕ*" : "Possession Date*",
		propertyAge:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಆಸ್ತಿಯ ವಯಸ್ಸು (ವರ್ಷಗಳಲ್ಲಿ)"
					: "Property age (In years)",
		facing: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬಾಗಿಲು*" : "Facing*",
		apartment: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಅಪಾರ್ಟ್‌ಮೆಂಟ್" : "Apartment",
		indipendentHouse: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ವತಂತ್ರ ಮನೆ" : "Independent House",
		villa: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವಿಲ್ಲಾ" : "Villa",
		plot: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ಲಾಟ್" : "Plot",
		landResidential:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿ (ವಾಸಸ್ಥಳ) " : "Land (Residential)",
		coLiving: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕೋ-ಲಿವಿಂಗ್/ಪಿಜಿ" : "Co-living/PG",
		rowHouse: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ರೋ ಹೌಸ್" : "Row House",
		builderFloorApt:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಬಿಲ್ಡರ್ ಫ್ಲೋರ್ ಅಪಾರ್ಟ್‌ಮೆಂಟ್"
					: "Builder Floor Apartment",
		penthouse: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪೆಂಟ್ಹೌಸ್" : "Penthouse",
		studioApartment:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ಟೂಡಿಯೊ ಅಪಾರ್ಟ್‌ಮೆಂಟ್" : "Studio Apartment",
		landCommercial:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿ (ವಾಣಿಜ್ಯಿಕ)" : "Land (Commercial)",
		building: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕಟ್ಟಡ" : "Building",
		shop: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ದೋಕಾನ" : "Shop",
		officeSpace: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕಾರ್ಯಾಲಯ ಸ್ಥಳ" : "Office Space",
		coWorking: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕೋ-ವರ್ಕಿಂಗ್" : "Co-working",
		plotCommercial:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿ (ವಾಣಿಜ್ಯಿಕ)" : "Plot (Commercial)",
		landAgricultural:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿ (ಕೃಷಿ)" : "Land (Agricultural)",
		farmHouse: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಫಾರ್ಮ್ ಹೌಸ್" : "Farm House",
		farmPlot: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಫಾರ್ಮ್ ಪ್ಲಾಟ್" : "Farm Plot",
		landIndustrial: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿ (ಕೈಗಾರಿಕ)" : "Land (Industrial)",
		factory: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಕಾರ್ಖಾನೆ" : "Factory",
		readyToMoveIn:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಹಾಜರಾಗಿ ಸ್ಥಳಾಂತರಿಸಲು ಸಿದ್ಧ" : "Ready to move in",
		underConstruction:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ನಿರ್ಮಾಣದಡಿಯಲ್ಲಿ" : "Under construction",
		builtToSuit:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸೂಟ್ ಮಾಡಲು ನಿರ್ಮಿಸಲಾಗಿದೆ" : "Built to suit",
		bareShell: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬೇರ್ ಶೆಲ್" : "Bare shell",
		immediately: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ತಕ್ಷಣ" : "Immediately",
		selectDate: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ" : "Select date",
		readyForRegistration:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ನೋಂದಣಿಗೆ ಸಿದ್ಧ" : "Ready for registration",
		north: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಉತ್ತರ" : "North",
		south: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ದಕ್ಷಿಣ" : "South",
		east: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪೂರ್ವ" : "East",
		west: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪಶ್ಚಿಮ" : "West",
		northEast: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಉತ್ತರ ಪೂರ್ವ" : "North east",
		northWest: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಉತ್ತರ ಪಶ್ಚಿಮ" : "North west",
		southEast: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ದಕ್ಷಿಣ ಪೂರ್ವ" : "South east",
		southWest: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ದಕ್ಷಿಣ ಪಶ್ಚಿಮ" : "South west",
	};

	const residential = [
		localLang.apartment,
		localLang.indipendentHouse,
		localLang.villa,
		localLang.plot,
		localLang.landResidential,
		localLang.coLiving,
		localLang.rowHouse,
		localLang.builderFloorApt,
		localLang.penthouse,
		localLang.studioApartment,
	];

	const residentialWOland = [
		localLang.apartment,
		localLang.indipendentHouse,
		localLang.villa,
		localLang.plot,
		localLang.coLiving,
		localLang.rowHouse,
		localLang.builderFloorApt,
		localLang.penthouse,
		localLang.studioApartment,
	];

	const commercial = [
		localLang.landCommercial,
		localLang.building,
		localLang.shop,
		localLang.officeSpace,
		localLang.coWorking,
		localLang.plotCommercial,
	];

	const commercialWOland = [
		localLang.building,
		localLang.shop,
		localLang.officeSpace,
		localLang.coWorking,
		localLang.plotCommercial,
	];

	const agricultural = [localLang.landAgricultural, localLang.farmHouse, localLang.farmPlot];

	const agriculturalWOland = [localLang.farmHouse, localLang.farmPlot];

	const industrial = [
		localLang.landIndustrial,
		localLang.factory,
		router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಖನಿ" : "Mining",
	];

	const industrialWOland = [localLang.factory];

	// const all_subtypes = ["SEZ", "Hospitality", "Healthcare", "Educational", "Commercial", "Industrial", "Other"];

	// const land_subtypes = ["SEZ", "Commercial", "Industrial", "Other"];

	// const office_subtypes = ["SEZ", "Commercial", "Industrial", "Other"];

	// const sez_subtypes = ["SEZ", "Other"];

	// const warehouse_subtypes = ["SEZ", "Commercial", "Industrial", "Agricultural", "Cold Storage", "Other"];

	const Availabilty = [
		localLang.readyToMoveIn,
		localLang.underConstruction,
		localLang.builtToSuit,
		localLang.bareShell,
	];

	const RentAvailabilty = [localLang.immediately, localLang.selectDate];

	const ApartmentAvailabilty = [localLang.readyToMoveIn, localLang.underConstruction];

	const PlotResidentialAvailability = [localLang.readyForRegistration, localLang.underConstruction];

	const WarehousingAvailabilty = [
		localLang.readyToMoveIn,
		localLang.underConstruction,
		// "Built to suit",
	];

	const Facing = [
		localLang.north,
		localLang.south,
		localLang.east,
		localLang.west,
		localLang.northEast,
		localLang.northWest,
		localLang.southEast,
		localLang.southWest,
	];

	/* Property Type options */

	switch (property_transaction_type) {
		case "resale":
			switch (property_category_type) {
				case "residential":
					pTypes.push(...residentialWOland);
					break;
				case "commercial":
					pTypes.push(...commercialWOland);
					break;
				case "agricultural":
					pTypes.push(...agriculturalWOland);
					break;
				case "industrial":
					pTypes.push(...industrialWOland);
					break;
				default:
					pTypes.push();
					break;
			}
			break;
		default:
			switch (property_category_type) {
				case "residential":
					pTypes.push(...residential);
					break;
				case "commercial":
					pTypes.push(...commercial);
					break;
				case "agricultural":
					pTypes.push(...agricultural);
					break;
				case "industrial":
					pTypes.push(...industrial);
					break;
				default:
					pTypes.push();
					break;
			}
	}

	/* Availability / Property Status options */

	switch (propertyType) {
		case localLang.apartment:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.indipendentHouse:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.villa:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.plot:
			AvailItems.push(...PlotResidentialAvailability);
			break;
		case localLang.farmPlot:
			AvailItems.push(...PlotResidentialAvailability);
			break;
		case localLang.plotCommercial:
			AvailItems.push(...PlotResidentialAvailability);
			break;
		case localLang.coLiving:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.rowHouse:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.builderFloorApt:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.penthouse:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.coWorking:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.building:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.shop:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.officeSpace:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case localLang.farmHouse:
			AvailItems.push(...ApartmentAvailabilty);
			break;
		case "Warehouse":
			AvailItems.push(...WarehousingAvailabilty);
			break;
		case localLang.factory:
			AvailItems.push(...WarehousingAvailabilty);
			break;
		default:
			AvailItems.push(...Availabilty);
	}

	/* Property Subtype options */

	// switch (propertyType) {
	// 	case "Land (Commercial)":
	// 		subtype_options.push(...land_subtypes);
	// 		break;
	// 	case "Plot (Commercial)":
	// 		subtype_options.push(...land_subtypes);
	// 		break;
	// 	case "Building":
	// 		subtype_options.push(...all_subtypes);
	// 		break;
	// 	case "Shop":
	// 		subtype_options.push(...all_subtypes);
	// 		break;
	// 	case "Office Space":
	// 		subtype_options.push(...office_subtypes);
	// 		break;
	// 	case "Co-working":
	// 		subtype_options.push(...office_subtypes);
	// 		break;
	// 	case "Warehouse":
	// 		subtype_options.push(...warehouse_subtypes);
	// 		break;
	// 	case "Land (Industrial)":
	// 		subtype_options.push(...land_subtypes);
	// 		break;
	// 	case "Factory":
	// 		subtype_options.push(...sez_subtypes);
	// 		break;
	// 	case "Mining":
	// 		subtype_options.push(...sez_subtypes);
	// 		break;
	// 	default:
	// 		subtype_options.push();
	// 		break;
	// }

	return (
		<PaperContainer id="property-details">
			{/* Transaction Type */}
			{property_transaction_type !== "ofb" ? (
				<FormControl
					id="transaction-type"
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
						// marginTop: "2rem",
					}}
					error={transactionTypeError}
				>
					<InputLabel id="demo-simple-select-helper-label">{localLang.transactionType}</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={property_transaction_type}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						label="Transaction type*"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setTransactionTypeError(false);
							dispatch(setCreateNewPropertyTransactionTypeThunk(event.target.value));
							dispatch(setCreateNewPropertyTypeThunk([]));
							dispatch(setCreateNewPropertySubTypeThunk(""));
							dispatch(setCreateNewPropertyMinPriceThunk(0));
							dispatch(setCreateNewPropertyMaxPriceThunk(0));
							dispatch(setCreateNewPropertySecurityDepositThunk(0));
							dispatch(setCreateNewPropertyAvailabiltyThunk([]));
							dispatch(setCreateNewePropertyPossessionDateThunk(""));
							dispatch(setCreateNewPropertyAgeThunk(0));
							dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
						}}
						// disableScrollLock={true}
					>
						<MenuItem value="buy">{localLang.sale}</MenuItem>
						<MenuItem value="rent">{localLang.rent}</MenuItem>
						<MenuItem value="resale">{localLang.resale}</MenuItem>
					</Select>
					<FormHelperText>{transactionTypeError ? localLang.pleaseSelectTransactionType : ""}</FormHelperText>
				</FormControl>
			) : null}

			{/* Property Category */}

			{property_transaction_type !== "" ? (
				<FormControl
					id="property-category"
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					error={propertyCategoryError}
				>
					<InputLabel id="demo-simple-select-helper-label">
						{(property_transaction_type === "ofb" ? localLang.ofb : localLang.property) + localLang.category}
					</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={property_category_type}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						label={(property_transaction_type === "ofb" ? localLang.ofb : localLang.property) + localLang.category}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setPropertyCategoryError(false);
							if (event.target.value === "warehousing") {
								dispatch(setCreateNewPropertyTypeThunk(["Warehouse"]));
								dispatch(setCreateNewPropertyCategoryTypeThunk(event.target.value));
								dispatch(setCreateNewPropertySubTypeThunk(""));
								dispatch(setCreateNewPropertyMinPriceThunk(0));
								dispatch(setCreateNewPropertyMaxPriceThunk(0));
								dispatch(setCreateNewPropertySecurityDepositThunk(0));
								dispatch(setCreateNewPropertyAvailabiltyThunk([]));
								dispatch(setCreateNewePropertyPossessionDateThunk(""));
								dispatch(setCreateNewPropertyAgeThunk(0));
								dispatch(setCreateNewPropertyUnitFacingThunk([]));
								dispatch(setCreateNewPropertyLandFacingThunk([]));
							} else {
								dispatch(setCreateNewPropertyTypeThunk([]));
								dispatch(SetIsStudioThunk(false));
								dispatch(setCreateNewPropertyCategoryTypeThunk(event.target.value));
								dispatch(setCreateNewPropertySubTypeThunk(""));
								dispatch(setCreateNewPropertyMinPriceThunk(0));
								dispatch(setCreateNewPropertyMaxPriceThunk(0));
								dispatch(setCreateNewPropertySecurityDepositThunk(0));
								dispatch(setCreateNewPropertyAvailabiltyThunk([]));
								dispatch(setCreateNewePropertyPossessionDateThunk(""));
								dispatch(setCreateNewPropertyAgeThunk(0));
								dispatch(setCreateNewPropertyUnitFacingThunk([]));
								dispatch(setCreateNewPropertyLandFacingThunk([]));
							}
						}}
					>
						{property_transaction_type === "ofb" && (
							<MenuItem value="public infrastructure">{localLang.publicInfrastructure}</MenuItem>
						)}
						{property_transaction_type === "ofb" && (
							<MenuItem value="joint development">{localLang.jointDevelopment}</MenuItem>
						)}
						{property_transaction_type === "ofb" && <MenuItem value="joint venture">{localLang.jointVenture}</MenuItem>}
						<MenuItem value="residential">{localLang.residential}</MenuItem>
						<MenuItem value="commercial">{localLang.commercial}</MenuItem>
						<MenuItem value="agricultural">{localLang.agricultural}</MenuItem>
						<MenuItem value="warehousing">{localLang.warehousing}</MenuItem>
						<MenuItem value="industrial">{localLang.industrial}</MenuItem>
					</Select>
					<FormHelperText>{propertyCategoryError ? localLang.pleseSelectCategory : ""}</FormHelperText>
				</FormControl>
			) : null}

			{/* Property Type */}

			{property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_category_type !== "public infrastructure" &&
			property_category_type !== "joint development" &&
			property_category_type !== "joint venture" &&
			property_category_type !== "warehousing" ? (
				<FormControl
					id="property-type"
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					error={propertyTypeError}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{(property_transaction_type === "ofb" ? localLang.ofb : localLang.property) + localLang.type}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_type}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						label={(property_transaction_type === "ofb" ? "OFB" : "Property") + " type*"}
						onChange={handleChangePropertyType}
						renderValue={(selected) => selected?.join(", ")}
					>
						{pTypes?.map((name: string) => (
							<MenuItem
								key={name}
								value={name}
							>
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
					<FormHelperText>{propertyTypeError ? localLang.pleaseSelectPropertyType : ""}</FormHelperText>
				</FormControl>
			) : null}

			{/* Property Subtype */}

			{/* {(property_category_type == "commercial" ||
				property_category_type == "industrial" ||
				property_category_type == "warehousing") &&
			property_type?.length !== 0 ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{(property_transaction_type === "ofb" ? "OFB" : "Property") + " subtype :"}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_subtype}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							dispatch(setCreateNewPropertySubTypeThunk(event.target.value));
						}}
						label={(property_transaction_type === "ofb" ? "OFB" : "Property") + " subtype :"}
					>
						{subtype_options?.map((name: string) => (
							<MenuItem
								key={name}
								value={name}
							>
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null} */}

			{/* Price */}

			{property_transaction_type !== "ofb" &&
			property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_type?.length !== 0 ? (
				<TextContainer id="property-price">
					{property_transaction_type == "buy" &&
					!property_type?.includes("Independent House") &&
					!property_type?.includes("Land (Residential)") &&
					!property_type?.includes("Land (Commercial)") &&
					!property_type?.includes("Land (Agricultural)") &&
					!property_type?.includes("Land (Industrial)") &&
					!property_type?.includes("Co-living/PG") &&
					!property_type?.includes("Building") &&
					!property_type?.includes("Shop") &&
					!property_type?.includes("Office Space") &&
					!property_type?.includes("Co-working") &&
					!property_type?.includes("Warehouse") &&
					!property_type?.includes("Factory") &&
					!property_type?.includes("Mining") ? (
						<TextContainer
							sx={{
								alignItems: "flex-start",
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									gap: "1.5rem",
								},
							}}
						>
							<TextField
								value={property_min_price === 0 ? "" : NumberToCurrencyPipe(propertyMinPrice)}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem 0rem 0rem 0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								inputProps={{
									min: 0,
								}}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setCreateNewPropertyMinPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(property_max_price !== 0 && CurrencyToNumberPipe(event.target.value) > propertyMaxPrice) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
										// setSubmitDisabled(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										setPriceMinError(false);
										// setSubmitDisabled(false);
									}
								}}
								label={localLang.minPrice}
								error={errorMinPrice || priceMinError}
								helperText={
									errorMinPrice || priceMinError ? localLang.valueMoreTnanMaxPrice : NumberInWords(propertyMinPrice)
								}
								autoComplete="off"
							/>

							<TextField
								value={property_max_price === 0 ? "" : NumberToCurrencyPipe(propertyMaxPrice)}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem 0rem 0rem 0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								inputProps={{
									min: 0,
								}}
								autoComplete="off"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setCreateNewPropertyMaxPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(property_min_price !== 0 && CurrencyToNumberPipe(event.target.value) < propertyMinPrice) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
										// setSubmitDisabled(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										setPriceMaxError(false);
										// setSubmitDisabled(false);
									}
								}}
								label={localLang.maxPrice}
								error={errorMaxPrice || priceMaxError}
								helperText={
									errorMaxPrice || priceMaxError ? localLang.valueLessThanMinPrice : NumberInWords(property_max_price)
								}
							/>
						</TextContainer>
					) : (
						<TextContainer sx={{ alignItems: "flex-start" }}>
							<TextField
								value={property_max_price === 0 ? "" : NumberToCurrencyPipe(propertyMaxPrice)}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch(setCreateNewPropertyMinPriceThunk(CurrencyToNumberPipe(event.target.value)));
									dispatch(setCreateNewPropertyMaxPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (isNaN(CurrencyToNumberPipe(event.target.value))) {
										setErrorMaxPrice(true);
										// setSubmitDisabled(true);
									} else {
										setErrorMaxPrice(false);
										setPriceMaxError(false);
										// setSubmitDisabled(false);
									}
								}}
								autoComplete="off"
								sx={{
									width: "100%",
									[theme.breakpoints.down("sm")]: {
										margin: "0rem",
										minWidth: "100%",
									},
									"& .MuiOutlinedInput-root": {
										borderRadius: "0.75rem",
									},
								}}
								label={
									property_transaction_type == "resale" ||
									(property_transaction_type !== "rent" &&
										(property_type?.includes("Independent House") ||
											property_type?.includes("Land (Residential)") ||
											property_type?.includes("Land (Commercial)") ||
											property_type?.includes("Land (Agricultural)") ||
											property_type?.includes("Land (Industrial)") ||
											property_type?.includes("Co-living/PG") ||
											property_type?.includes("Building") ||
											property_type?.includes("Shop") ||
											property_type?.includes("Office Space") ||
											property_type?.includes("Co-working") ||
											property_type?.includes("Warehouse") ||
											property_type?.includes("Factory") ||
											property_type?.includes("Mining")))
										? localLang.price
										: localLang.monthlyRent
								}
								error={errorMaxPrice || priceMaxError}
								helperText={
									errorMaxPrice || priceMaxError ? localLang.enterAValidNumber : NumberInWords(property_max_price)
								}
								// helperText={NumberInWords(property_max_price)}
							/>
							{property_transaction_type == "rent" && property_category_type !== "" && property_type?.length !== 0 ? (
								<TextField
									value={property_security_deposit.max === 0 ? "" : NumberToCurrencyPipe(property_security_deposit.max)}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										dispatch(setCreateNewPropertySecurityDepositThunk(CurrencyToNumberPipe(event.target.value)));
									}}
									autoComplete="off"
									sx={{
										width: "100%",
										[theme.breakpoints.down("sm")]: {
											margin: "0rem 0rem 0.5rem 0rem",
											minWidth: "100%",
										},
										"& .MuiOutlinedInput-root": {
											borderRadius: "0.75rem",
										},
									}}
									label={localLang.securityDeposit}
									helperText={NumberInWords(property_security_deposit.max)}
								/>
							) : null}
						</TextContainer>
					)}
				</TextContainer>
			) : null}

			{/* Property Status / Availability */}

			{(property_transaction_type !== "ofb" &&
				property_transaction_type !== "" &&
				property_category_type !== "" &&
				property_type?.length !== 0 &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Mining")) ||
			(property_transaction_type == "rent" &&
				property_category_type !== "" &&
				(property_type?.includes("Land (Residential)") ||
					property_type?.includes("Land (Commercial)") ||
					property_type?.includes("Land (Agricultural)") ||
					property_type?.includes("Land (Industrial)") ||
					property_type?.includes("Mining"))) ? (
				<FormControl
					id="property-availability"
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					error={availabilityError}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{property_transaction_type !== "rent" ? localLang.propertyStatus : localLang.availableFrom}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_availability?.map(
							(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
						)}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={(e: SelectChangeEvent<string[]>) => {
							handleChangeAvailability(e);
							setAvailabilityError(false);
						}}
						input={
							<OutlinedInput
								label={property_transaction_type !== "rent" ? localLang.propertyStatus : localLang.availableFrom}
							/>
						}
						renderValue={(selected) => selected.join(", ")}
					>
						{property_transaction_type !== "rent"
							? AvailItems?.map((name: string) => (
									<MenuItem
										key={name}
										value={name}
									>
										<ListItemText primary={name} />
									</MenuItem>
								))
							: RentAvailabilty?.map((name: string) => (
									<MenuItem
										key={name}
										value={name}
									>
										<ListItemText primary={name} />
									</MenuItem>
								))}
					</Select>
					<FormHelperText>{availabilityError ? localLang.pleaseSelectPropertyStatus : ""}</FormHelperText>
				</FormControl>
			) : null}

			{/* Possession Date */}

			{property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_type?.length !== 0 &&
			(property_availability?.includes(localLang.underConstruction) ||
				property_availability?.includes("under_construction") ||
				property_availability?.includes("Select date") ||
				property_availability?.includes("select_date")) ? (
				<div
					id="possession-date"
					style={{ width: "100%" }}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label={localLang.possessionDate}
							value={dayjs(property_possession_date)}
							// onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							// 	dispatch(setCreateNewePropertyPossessionDateThunk(new Date(e)));
							// }}
							onChange={(date: Dayjs | null) => {
								if (date) {
									dispatch(setCreateNewePropertyPossessionDateThunk(date.toDate()));
								}
							}}
							disablePast
							format="DD/MM/YYYY"
							sx={{
								width: "100%",
								"& .MuiOutlinedInput-root": {
									borderRadius: "0.75rem",
								},
							}}
						/>
					</LocalizationProvider>
				</div>
			) : null}

			{/* Property Age */}

			{property_transaction_type == "resale" &&
			property_category_type !== "" &&
			property_type?.length !== 0 &&
			!property_type?.includes("Land (Residential)") &&
			!property_type?.includes("Land (Commercial)") &&
			!property_type?.includes("Land (Agricultural)") &&
			!property_type?.includes("Land (Industrial)") &&
			!property_type?.includes("Plot") &&
			!property_type?.includes("Farm Plot") &&
			!property_type?.includes("Plot (Commercial)") &&
			!property_type?.includes("Mining") &&
			!property_availability?.includes("Under construction") &&
			!property_availability?.includes("under_construction") &&
			!property_availability?.includes("Select date") &&
			!property_availability?.includes("select_date") ? (
				<TextField
					value={property_age === 0 ? "" : property_age}
					inputProps={{
						min: 0,
					}}
					autoComplete="off"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						dispatch(setCreateNewPropertyAgeThunk(Number(event.target.value.replace(/\D/g, ""))));
						if (isNaN(Number(event.target.value.replace(/\D/g, "")))) {
							setErrorPropertyAge(true);
						} else {
							setErrorPropertyAge(false);
						}
					}}
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
					error={errorPropertyAge}
					helperText={errorPropertyAge ? "Enter a valid number" : ""}
					label={localLang.propertyAge}
				/>
			) : null}

			{/* Unit Facing */}

			{(property_transaction_type !== "" &&
				property_category_type !== "" &&
				property_type?.length !== 0 &&
				router.locale !== "ar-AE" &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Warehouse") &&
				!property_type?.includes("Factory") &&
				!property_type?.includes("Mining") &&
				!property_type?.includes("Office Space") &&
				!property_type?.includes("Co-working")) ||
			(property_transaction_type == "buy" &&
				property_category_type !== "" &&
				router.locale !== "ar-AE" &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Warehouse") &&
				!property_type?.includes("Factory") &&
				!property_type?.includes("Mining")) ||
			(property_transaction_type == "resale" &&
				property_category_type !== "" &&
				router.locale !== "ar-AE" &&
				!property_type?.includes("Land (Residential)") &&
				!property_type?.includes("Land (Commercial)") &&
				!property_type?.includes("Land (Agricultural)") &&
				!property_type?.includes("Land (Industrial)") &&
				!property_type?.includes("Warehouse") &&
				!property_type?.includes("Factory") &&
				!property_type?.includes("Mining")) ||
			(property_transaction_type == "rent" &&
				property_category_type !== "" &&
				router.locale !== "ar-AE" &&
				property_type?.includes("Office Space")) ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">
						{property_type?.includes("Plot") ||
						property_type?.includes("Farm Plot") ||
						property_type?.includes("Plot (Commercial)")
							? localLang.facing
							: localLang.facing}
					</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple={
							property_transaction_type == "buy" &&
							(property_type?.includes("Apartment") ||
								property_type?.includes("Plot") ||
								property_type?.includes("Farm Plot") ||
								property_type?.includes("Plot (Commercial)") ||
								property_type?.includes("Row House") ||
								property_type?.includes("Villa") ||
								property_type?.includes("Builder Floor Apartment") ||
								property_type?.includes("Penthouse"))
								? true
								: false
						}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						value={property_unit_facing}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						onChange={handleChangeUnitFacing}
						input={
							<OutlinedInput
								label={
									property_type?.includes("Plot") ||
									property_type?.includes("Farm Plot") ||
									property_type?.includes("Plot (Commercial)")
										? localLang.facing
										: localLang.facing
								}
							/>
						}
						renderValue={(selected) => selected.join(", ")}
					>
						{Facing?.map((name) => (
							<MenuItem
								key={name}
								value={name}
							>
								{property_transaction_type == "buy" &&
								(property_type?.includes("Apartment") ||
									property_type?.includes("Plot") ||
									property_type?.includes("Farm Plot") ||
									property_type?.includes("Plot (Commercial)") ||
									property_type?.includes("Row House") ||
									property_type?.includes("Villa") ||
									property_type?.includes("Builder Floor Apartment") ||
									property_type?.includes("Penthouse")) ? (
									<Checkbox checked={property_unit_facing.indexOf(name) > -1} />
								) : null}

								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}

			{/* Land Facing */}

			{property_transaction_type !== "ofb" &&
			property_transaction_type !== "" &&
			property_category_type !== "" &&
			property_type?.length !== 0 &&
			router.locale !== "ar-AE" &&
			(property_type?.includes("Land (Residential)") ||
				property_type?.includes("Land (Commercial)") ||
				property_type?.includes("Land (Agricultural)") ||
				property_type?.includes("Land (Industrial)") ||
				property_type?.includes("Warehouse") ||
				property_type?.includes("Factory") ||
				property_type?.includes("Mining")) ? (
				<FormControl
					sx={{
						width: "100%",
						"& .MuiOutlinedInput-root": {
							borderRadius: "0.75rem",
						},
					}}
				>
					<InputLabel id="demo-multiple-checkbox-label">{localLang.facing}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={property_land_facing}
						sx={{
							width: "100%",
							"& .MuiOutlinedInput-root": {
								borderRadius: "0.75rem",
							},
						}}
						MenuProps={{
							PaperProps: {
								sx: {
									"& .MuiMenu-list": {
										direction: router.locale === "ar-AE" ? "rtl" : "ltr",
									},
								},
							},
						}}
						onChange={handleChangeLandFacing}
						input={<OutlinedInput label={localLang.facing} />}
						renderValue={(selected) => selected.join(", ")}
					>
						{Facing.map((name) => (
							<MenuItem
								key={name}
								value={name}
							>
								<Checkbox
									sx={{ display: "none" }}
									checked={property_land_facing.indexOf(name) > -1}
								/>
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}
		</PaperContainer>
	);
};

export default PropertyDetails;
