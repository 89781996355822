import Head from "next/head";
import { withRouter } from "next/router";
import React from "react";

const Header = (props: any) => {
	const { router }: { router: any } = props.router;
	const keywords: string = props.keywords;
	const description: string = props.description;
	const title: string = props.title;
	const canonicalUrl: string = props.canonicalUrl;
	const ogImageParams: {
		ogImageSquareUrl: string;
		ogImageRectangleUrl: string;
		ogImageSquareType: string;
		ogImageRectangleType: string;
		ogImageSquareWidth: number;
		ogImageSquareHeight: number;
		ogImageRectangleWidth: number;
		ogImageRectangleHeight: number;
	} = props.ogImageParams;

	const MainOgDescription: string = props.MainOgDescription;
	return (
		<React.Fragment>
			<Head>
				<link
					rel="preconnect"
					href={process.env.PRODUCTION_URL}
					crossOrigin="anonymous"
				/>
				<link
					rel="preconnect"
					href={process.env.CDN_URL}
					crossOrigin="anonymous"
				/>
				<link
					rel="preconnect"
					href={process.env.API_CDN_URL}
					crossOrigin="anonymous"
				/>
				<link
					rel="preconnect"
					href="https://google.com"
					crossOrigin="anonymous"
				/>
				<link
					rel="preconnect"
					href="https://www.googletagmanager.com"
					crossOrigin="anonymous"
				/>
				<link
					rel="preconect"
					href="https://www.google-analytics.com"
					crossOrigin="anonymous"
				/>
				<link
					rel="canonical"
					href={canonicalUrl ? canonicalUrl : process.env.PRODUCTION_URL + router.asPath.substring(1)}
				/>
				<meta
					httpEquiv="Content-Type"
					content="text/html; charset=utf-8"
				/>
				<meta
					httpEquiv="cache-control"
					content="Private"
				/>
				<meta
					httpEquiv="Expires"
					content="31536000"
				/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0"
				/>
				{/* <meta name="theme-color" content="#F38A94" /> */}
				<meta
					name="theme-color"
					media="(prefers-color-scheme: light)"
					content="#FAFAFA"
				/>
				<meta
					name="theme-color"
					media="(prefers-color-scheme: dark)"
					content="#303030"
				/>
				<meta
					name="Description"
					content={description ? description : "Beegru gets properties"}
				/>
				<meta
					name="mobile-web-app-capable"
					content="yes"
				/>
				<meta
					name="keywords"
					content={
						keywords
							? keywords
							: "marketplace,boost,property,real,estate,beegru,advisory,crowd,source,geography,geodata,geospatial,aggregator,bhk,apartment,villa,plot,land,buy,jd,jv,warehouse,officespace,commercial,residential,agricultural,farmland,farmplot,service,agent,developer,builder,landowner,professional,consultant,architect,engineer,interior,designer,contractor,contracting,construction,construction,material,material,manufacturer,manufacturer,wholesaler,wholesaler,retailer,retailer,service,provider,service,provider,property,management,property,management,property,valuation,property,valuation,property,legal,property,legal,property,finance,property,finance,property,insurance,property,insurance,property,advisory,property,advisory,property,consultancy,property,consultancy,property,marketing,property,marketing,property,advertising,property,advertising,property,technology,property,technology,property,software,property,software,property,crm,property,crm,property,erp,property,erp,property,website,property,website,property,portal,property,portal,property,listing,property,listing,property,search,property,search,prop,startup"
					}
				/>
				<meta
					name="robots"
					content="index, follow"
				/>
				<meta
					property="og:type"
					content="website"
				/>
				<meta
					property="og:site_name"
					content={title ? title : "Beegru"}
				/>
				<meta
					property="og:title"
					content={MainOgDescription ? MainOgDescription : "Beegru"}
				/>
				<meta
					property="og:description"
					content={description ? description : "Beegru gets properties"}
				/>
				<meta
					property="og:url"
					content={canonicalUrl ? canonicalUrl : process.env.PRODUCTION_URL + router.asPath.substring(1)}
				/>
				<meta
					property="og:image"
					content={
						ogImageParams ? ogImageParams.ogImageSquareUrl : process.env.CDN_URL + "images/icons/logo/og_512.jpg"
					}
				/>
				<meta
					property="og:image:alt"
					content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
				/>
				<meta
					property="og:image:type"
					content={ogImageParams ? ogImageParams.ogImageSquareType : "image/jpeg"}
				/>
				<meta
					property="og:image:width"
					content={"512"}
				/>
				<meta
					property="og:image:height"
					content={"512"}
				/>
				{/* new og Rectangle Image */}
				<meta
					property="og:image:rectangle"
					content={
						ogImageParams ? ogImageParams.ogImageRectangleUrl : process.env.CDN_URL + "images/icons/logo/og_512.jpg"
					}
				/>
				<meta
					property="og:image:rectangle:alt"
					content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
				/>
				<meta
					property="og:image:rectangle:width"
					content={(ogImageParams ? ogImageParams.ogImageRectangleWidth : 1200).toString()}
				/>
				<meta
					property="og:image:rectangle:height"
					content={(ogImageParams ? ogImageParams.ogImageRectangleWidth : 630).toString()}
				/>

				{/*new og  Square Image */}
				<meta
					property="og:image:square"
					content={
						ogImageParams ? ogImageParams.ogImageSquareUrl : process.env.CDN_URL + "images/icons/logo/og_512.jpg"
					}
				/>
				<meta
					property="og:image:square:alt"
					content={process.env.CDN_URL + "images/icons/logo/og_512.jpg"}
				/>
				<meta
					property="og:image:square:width"
					content={(ogImageParams ? ogImageParams.ogImageSquareWidth : 512).toString()}
				/>
				<meta
					property="og:image:square:height"
					content={(ogImageParams ? ogImageParams.ogImageSquareHeight : 512).toString()}
				/>
				<meta
					property="og:locale"
					content="en_IN"
				/>
				<meta
					property="og:image:secure_url"
					content={
						ogImageParams ? ogImageParams.ogImageRectangleUrl : process.env.CDN_URL + "images/icons/logo/og_512.jpg"
					}
				/>
				<meta
					property="og:image:type"
					content={"image/jpeg"}
				/>
				<meta
					property="og:image:square:type"
					content={ogImageParams ? ogImageParams.ogImageSquareType : "image/jpeg"}
				/>
				<meta
					property="og:image:rectangle:type"
					content={ogImageParams ? ogImageParams.ogImageRectangleType : "image/jpeg"}
				/>
				<meta
					name="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:domain"
					content="beegru.com"
				/>
				<meta
					property="twitter:url"
					content={canonicalUrl ? canonicalUrl : process.env.PRODUCTION_URL + router.asPath.substring(1)}
				/>
				<meta
					name="twitter:title"
					content={title ? title : "Beegru"}
				/>
				<meta
					name="twitter:description"
					content={description ? description : "Beegru gets properties"}
				/>
				<meta
					name="twitter:image"
					content={
						ogImageParams ? ogImageParams.ogImageSquareUrl : process.env.CDN_URL + "images/icons/logo/og_512.jpg"
					}
				/>
				<link
					rel="icon"
					href={process.env.CDN_URL + "images/icons/logo/favicon_48.png"}
				/>
				<link
					rel="shortcut icon"
					type="image/png"
					href={process.env.CDN_URL + "images/icons/logo/favicon_48.png"}
				/>
				<link
					rel="apple-touch-icon"
					href={process.env.CDN_URL + "images/icons/logo/favicon_192.png"}
				/>
				<link
					rel="manifest"
					href={process.env.CDN_URL + "manifest.json"}
				/>
				<title>{title ? title : "Beegru"}</title>
			</Head>
		</React.Fragment>
	);
};

export default withRouter(Header);
