/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../../../redux-magic/state-interface";
import { addSaveEntityBusinessProfileThunk } from "../../../../../redux-magic/store";

/* Component Imports */

import { Chip, IconButton, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import DeleteListingPropertyModal from "../../../../modals/PropertyModal/DeleteListingProperty";

/* Icon Imports */

import { Bolt } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	objectFit: "cover",
	width: "100%",
	borderRadius: "12px 12px 0px 0px",
	position: "relative",
}));

const Image = styled("img")(({ theme }) => ({
	display: "block",
	objectFit: "cover",
	width: "100%",
	height: "4rem",
	borderRadius: "12px 12px 0px 0px",
	/*512*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		height: "5rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		height: "4rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		height: "5rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		height: "5.5rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		height: "6rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		height: "6.5rem",
	},
	/*iPadPro - 1024*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		height: "7rem",
	},
	/*1107*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		height: "7.5rem",
	},
	/*1190 ~~Desktop Layout Start~~*/
	[theme.breakpoints.up("md1190")]: {
		height: "8rem",
	},
	/*720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		height: "7rem",
	},
}));

const CustomChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "transaction_type",
})<{ transaction_type: string }>(({ theme, transaction_type }) => ({
	position: "absolute",
	top: "0.25rem",
	right: "0.25rem",
	height: "0.8rem",
	fontWeight: 500,
	fontSize: "0.6rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	"& .MuiChip-label": { padding: "0rem 0.35rem 0rem 0.35rem" },
	backgroundColor:
		transaction_type === "buy"
			? "#b7efc5"
			: transaction_type === "rent"
				? "#ffc6ac"
				: transaction_type === "resale"
					? "#caf0f8"
					: "#b7efc5",
	color:
		transaction_type === "buy"
			? "#1e4620"
			: transaction_type === "rent"
				? "#4c2708"
				: transaction_type === "resale"
					? "#212121"
					: "#1e4620",
}));

const BookmarkIconButton = styled(IconButton)(({ theme }) => ({
	background: "#E6DACF",
	padding: "0rem",
	width: "0.825rem",
	height: "0.825rem",
	borderRadius: "0px",
	"&:hover": {
		background: "#E6DACF",
	},
}));
const BoltContainer = styled("div")(({ theme }) => ({
	background: "white",
	"&:hover": {
		background: "white",
	},
}));

/*

 & Setting a constant image for when the image is not available or something goes wrong

*/

const fallbackImage: string = process.env.CDN_URL + "images/uploads/property.jpg";

const GridImage = ({
	_id,
	slug,
	images,
	dispatch,
	profile_context,
	session,
	loading,
	saved_content,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	transaction_type,
}: {
	_id: ObjectId | string;
	slug: string;
	images: string;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	session: any;
	loading: boolean;
	saved_content: Array<any>;
	created_by?: any;
	assigned_to?: any;
	isEditor?: boolean;
	boosting?: any;
	transaction_type: string;
}) => {
	const theme = useTheme();

	/*

	& Check if page is loaded. i loaded set to true. if page loads we use regular image. if not we use lqip image.

	*/
	const [isPageLoaded, SetIsPageLoaded] = React.useState<boolean>(false);

	React.useEffect(() => {
		SetIsPageLoaded(true);
	}, [loading]);

	/* Pathname */

	const router = useRouter();

	const pathname = React.useRef(router).current;

	/*

	& Next, Let's define a local state to handle menu.

	*/

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	/*

	& Next, Let's define a constant to hold anchorEl state.

	*/

	const [open, setOpen] = React.useState(false);
	const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

	const handleCloseDeleteModal = () => {
		setOpenDeleteModal(false);
	};

	/*

	& Next, Let's descride a function to chnage anchorEl state.

	*/

	const handleClick = (event: any) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleDelete = async () => {
		setOpenDeleteModal(true);
		setAnchorEl(null);
		setOpen(false);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};
	const [isHovered, setHovered] = React.useState(false);

	/*

	& Next, let's declare  local state to handle bookmark.

	*/

	const [bookmark, setBookmark] = React.useState(
		saved_content?.find((item) => {
			return profile_context && profile_context.is_business_profile
				? item.saved_by.business_profile_id ===
						(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
				: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
		}) !== undefined,
	);

	React.useEffect(() => {
		setBookmark(
			saved_content?.find((item) => {
				return profile_context && profile_context.is_business_profile
					? item.saved_by.business_profile_id ===
							(item.saved_by.is_business_profile ? profile_context?.business_profile_id : "")
					: item.saved_by.user_id === (item.saved_by.is_business_profile === false ? profile_context?.user_id : "");
			}) !== undefined,
		);
	}, [profile_context, saved_content]);

	const submit = async () => {
		if (session === null) {
			window.open("/auth/login");
		} else {
			await dispatch(
				addSaveEntityBusinessProfileThunk({
					is_business_profile: profile_context?.is_business_profile,
					user_id: profile_context?.user_id,
					business_profile_id: profile_context?.business_profile_id,
					entity_id: _id,
					entity_type: "property",
					add_remove: !bookmark ? "add" : "remove",
					url: pathname.asPath ? pathname.asPath : "",
				}),
			);
			setBookmark(!bookmark);
		}
	};

	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="rectangular"
					width="100%"
					height="100%"
					style={{ borderRadius: "16px", margin: "0rem" }}
				/>
			</React.Fragment>
		);
	}
	let BoostingCheck = boosting?.active ? true : false;

	/* Boosting end date */

	const date = new Date(boosting?.ends_at);

	/* Date , Month and year format*/

	const formattedDate = `
  			${date.getDate()}
  			${date.toLocaleString("en-US", {
					month: "short",
				})}
  			${date.getFullYear().toString().slice(2)}`;

	const BoostPaper = (
		<Paper
			sx={{
				position: "absolute",
				top: 13,
				left: 13,
				zIndex: 4,
				display: "flex",
				justifyContent: "center",
				alignItems: "space-between",
				height: "2.25rem",
				padding: "0.43rem",
				flexDirection: "column",
				overflow: "hidden",
				borderRadius: "16px",
				boxShadow: "none",
				backgroundColor: "white",
			}}
		>
			<BoltContainer
				// onClick={(event: any) => {
				//   event.preventDefault();
				//   session === null ? window.open("/auth/login") : submit();
				// }}
				sx={{
					display: "flex",
					width: !isHovered ? "1.5rem" : "11rem",
					height: "2rem",
					gap: "0.5rem",
					overflow: "hidden",
					borderRadius: "16px",
					transition: "width 0.25s ease-in-out",
				}}
			>
				<Bolt sx={{ color: theme.palette.primary.main, fontSize: "0.625rem" }} />
				<Typography
					sx={{
						color: "black",
					}}
				>
					{session === null ? "Boosted Property" : "Ends on " + formattedDate}
				</Typography>
			</BoltContainer>
		</Paper>
	);

	return (
		<React.Fragment>
			{/* Delete Modal */}

			<DeleteListingPropertyModal
				open={openDeleteModal}
				handleClose={handleCloseDeleteModal}
				creator_user_id={created_by?.userId}
				creator_business_profile_id={created_by?.business_profile_id}
				propertyId={_id}
				session={session}
				isEditor={isEditor ? isEditor : false}
				profile_context={profile_context}
				dispatch={dispatch}
				property_type={"property"}
			/>

			<Container>
				{/* Bookmark Icon */}

				{/* {session !== null ? ( */}
				{/* <BookmarkIconButton
					onClick={(event: any) => {
						event.preventDefault();
						session === null ? window.open("/auth/login") : submit();
					}}
					sx={{
						position: "absolute",
						top: 10,
						right: 10,
						zIndex: 4,
					}}
				>
					<Tooltip title="Favourite">
						{bookmark == false ? (
							<BookmarkBorderOutlined sx={{ color: "#623816", fontSize: "0.625rem" }} />
						) : (
							<BookmarkOutlined sx={{ color: "#623816", fontSize: "0.625rem" }} />
						)}
					</Tooltip>
				</BookmarkIconButton> */}
				{/* ) : null} */}

				{/* Boost Icon */}

				{/* {boosting?.active === true ? (
					<div
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={{
							position: "absolute",

							[theme.breakpoints.down("sm")]: {
								display: "none",
							},
						}}
					>
						<Box sx={{ width: "100%" }}>
							<div>{BoostPaper}</div>
						</Box>
					</div>
				) : null} */}

				{/* Options Menu */}

				{/* {isEditor ? (
					<ThreeDotIconButton
						onClick={(event: any) => {
							event.preventDefault();
							session === null ? signIn() : handleClick(event);
						}}
						sx={{
							position: "absolute",
							top: 12,
							right: 3,
							zIndex: 4,
						}}
					>
						<MoreVertOutlined sx={{ color: "#fafafa" }} />
					</ThreeDotIconButton>
				) : null}

				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					disableScrollLock={true}
				>
					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem
							onClick={() => {
								window.open(`/properties/${slug}`, "_self");
								handleClose();
							}}
						>
							Edit property
						</MenuItem>
					) : null}

					{session?.user?.dbUser?._id === created_by?.user_id ||
					session?.user?.dbUser?.access_level.includes("super_admin") ||
					(session?.user?.dbUser?.access_level.includes("admin") &&
						session?.user?.dbUser?.access_level.includes("business_profiles") &&
						assigned_to?.includes(session?.user?.dbUser?._id)) ? (
						<MenuItem onClick={handleDelete}>Delete property</MenuItem>
					) : null}
				</Menu> */}

				<Image
					sx={{
						objectFit: images.includes("images/uploads/placeholder_1080p_old.svg") ? "fill" : "cover",
					}}
					src={images ? (images.length > 0 ? (isPageLoaded ? images : images) : fallbackImage) : fallbackImage}
					alt="Property Image"
					referrerPolicy="no-referrer"
				/>
				{/* <CustomChip
					label={
						transaction_type && transaction_type === "buy"
							? "Sale"
							: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
					}
					transaction_type={transaction_type}
				/> */}
			</Container>
		</React.Fragment>
	);
};

export default GridImage;
