/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "bson";
import StateInterface from "../../redux-magic/state-interface";
import {
	FetchMoreBusinessProfilesThunk,
	setCreateNewPropertyCurrentPageThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewServiceCurrentPageThunk,
	setProfileContextThunk,
	setSearchAnimationDetailsThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../redux-magic/store";

/* Component Imports */

import {
	Alert,
	Backdrop,
	BottomNavigation,
	BottomNavigationAction,
	Chip,
	CircularProgress,
	Divider,
	FormControlLabel,
	Link,
	Radio,
	RadioGroup,
	Slide,
	Snackbar,
	SnackbarContent,
	Stack,
	SvgIcon,
	SwipeableDrawer,
	Typography,
	useTheme,
} from "@mui/material";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

/* Icon Imports */

import {
	AddCircle,
	BookmarkBorder,
	BookmarkBorderOutlined,
	Home,
	HomeOutlined,
	Search,
	SearchOutlined,
	WarningAmber,
} from "@mui/icons-material";
import PostOFB from "../../public/images/icons/home/post_ofb.svg";
import PostProperty from "../../public/images/icons/home/post_property.svg";
import PostService from "../../public/images/icons/home/post_service.svg";
import LoadingIcon from "../animations/common-animations/LoadingIcon";

/* Modal Imports */

import { updateSearchFilterAppliedThunk } from "../../redux-magic/thunks";
import Initials from "../cards/Initials";
// import BeegruButton from "../common-components/buttons/BeegruButton";
import CreateBusinessModal from "../modals/BusinessProfileModals/CreateBusinessProfileModal";
import CreateNewOFBWithUploadTool from "../modals/PropertyModal/CreateNewOFBWithUploadTool";
import CreatePropertyWithUploadTool from "../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../modals/ServiceModals/CreateServiceWithUploadTool";
import LocationAutoCompleteMobile from "../page-sections/Index/SearchMobileComponent/LocationAutoCompleteMobile";

/* Styled Components */

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "center",
	height: "4rem",
	position: "fixed",
	bottom: 0,
	right: 0,
	left: 0,
	zIndex: 5,
	background: theme.palette.mode == "dark" ? "#424242" : "#FFFFFF",
	opacity: 1,
	borderRadius: "1rem 1rem 0rem 0rem",
	boxShadow:
		"rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
	"& .MuiBottomNavigationAction-label": {
		fontSize: "0.75rem",
		fontWeight: 400,
		lineHeight: "0.875rem",
	},
	"& .Mui-selected": {
		margin: "0rem 0rem 0rem 0rem",
		"& .MuiBottomNavigationAction-label": {
			fontSize: "0.75rem",
			fontWeight: 500,
			lineHeight: "0.75rem",
			color: theme.palette.mode === "dark" ? "#FFAD69" : "#6e3037",
		},
	},
}));

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "space-between",
		padding: "0.5rem 0.5rem 0.5rem 0.5rem",
		borderRadius: "8px 8px 0px 0px",
		gap: "0.5rem",
		width: "100%",
		background: theme.palette.background.default,
		maxHeight: "70vh",
	},
}));

const Puller = styled("div")(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: "#E0E0E0",
	borderRadius: "8px",
	position: "absolute",
	left: "calc(50% - 15px)",
}));

const CustomSvgIcon = styled(SvgIcon)(({ theme }) => ({
	height: "5rem",
	width: "5rem",
	[theme.breakpoints.down("md")]: {
		height: "3rem",
		width: "3rem",
	},
}));

const DrawerCard = styled("div")(({ theme }) => ({
	background: theme.palette.background.paper,
	borderRadius: "8px",
	padding: "1rem",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	padding: "0rem",
	gap: "0rem",
	overflow: "hidden",
}));

const ProfilesContainer = styled("div")(({ theme }) => ({
	overflowY: "auto",
	width: "100%",
	gap: "1.5rem",
	maxHeight: "13rem",
	scrollbarWidth: "none",
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	width: "100%",
	margin: "0rem 0rem 0rem 0rem",
	padding: "0rem 1rem 0rem 0rem",
	display: "flex",
	flexDirection: "row-reverse",
	justifyContent: "space-between",
	alignItems: "center",
	"& .MuiRadio-root": {
		width: "1rem",
		height: "1rem",
	},
}));

const ProfileCardLink = styled(Link)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000000",
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	borderRadius: "8px",
	height: "3rem",
	width: "3rem",
	// "& .MuiAvatar-fallback": {
	//   background: `url(/images/icons/Avatar.svg) center no-repeat`,
	//   backgroundSize: "100% 100%",
	//   width: "100%",
	//   height: "100%",
	//   path: {
	//     display: "none",
	//   },
	// },
}));

const NameTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	margin: "0rem 0rem 0rem 0.75rem",
}));

const LoadingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "1rem",
}));

const ContentRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
	gap: "0.75rem",
	cursor: "pointer",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.875rem",
	color: theme.palette.mode == "dark" ? "#C6C6C6" : "#666666",
}));

const RadioWithLink = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 0rem 0.5rem 0rem",
}));

const CustomLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	fontSize: "1rem",
	fontWeight: 500,
	padding: "1rem 0.5rem 0.25rem 0.5rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
	"&:hover": {
		color: theme.palette.primary.main,
		cursor: "pointer",
	},
}));

const LocationChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	borderRadius: "8px",
	gap: "0.5rem",
	"& .MuiChip-label": {
		padding: "0rem",
		fontWeight: 400,
	},
	fontSize: "0.875rem",
	padding: "0.25rem 0.5rem 0.25rem 0.5rem",
})) as typeof Chip;

const BottomNavigate = ({
	session,
	dispatch,
	profile_context,
	new_listing_url,
	new_business_profile_url,
	newPropertyData,
	studio,
	newServiceData,
	search_filter_state,
	searchanimationsettings,
	searchLocation,
}: {
	session?: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_listing_url: StateInterface["new_listing_url"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings?: StateInterface["search_animation_settings"];
	searchLocation: any;
}) => {
	const theme = useTheme();

	/*

    * Profile context switcher variables and functions

  */

	const [openBusinessProfileModal, setOpenBusinessProfileModal] = React.useState(false);

	const [loading, setLoading] = React.useState(false);

	const [openProfileContextSnackbar, setOpenProfileContextSnackbar] = React.useState(false);

	// const [pageID, setPageID] = React.useState("");

	let businessProfiles: Array<any> = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages
					: []
				: []
			: []
		: [];

	let businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	const session_user = session ? (session.user ? session.user.dbUser : "") : "";

	/* Create new business profile modal functions */

	const handleOpenBusinessProfile = () => {
		setOpenBusinessProfileModal(true);
	};

	const handleCloseBusinessProfile = () => {
		setOpenBusinessProfileModal(false);
	};

	/* Change profile context function */

	const onChangeProfileContext = async (id: ObjectId | string) => {
		if (id) {
			setLoading(true);
			await dispatch(
				setProfileContextThunk({
					is_business_profile: id === session_user._id ? false : true,
					user_id: session_user ? session_user._id : "",
					business_profile_id: id,
					page_type:
						id === session_user._id ? "user" : businessProfiles.find((item: any) => item.page_id === id).page_type,
					user_kyc_status: session.user.dbUser.kyc_info.kyc_status,
					user_subscription_information: session.user.dbUser.subscription_information,
					remember_choice: true,
				}),
			);
			setLoading(false);
			setOpenProfileContextSnackbar(true);
		}
	};

	/* Show more business profiles */

	const handleClickShowMoreBusinessProfiles = async () => {
		setLoading(true);
		await dispatch(
			FetchMoreBusinessProfilesThunk({
				user_id: session_user ? session_user._id : "",
				pages: businessProfiles.map((page: any) => {
					return { page_id: page.page_id };
				}),
			}),
		);
		setLoading(false);
	};

	const userName = session
		? session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: session.user.dbUser.phone
		: "";

	/*

    * profile context, post drawer & search location open state

  */
	const [openPostMenu, setOpenPostMenu] = React.useState<boolean>(false);
	const [openSearchLocation, setOpenSearchLocation] = React.useState<boolean>(
		searchanimationsettings?.OpenLocationDrawer as boolean,
	);
	const [openProfiles, setOpenProfiles] = React.useState<boolean>(false);

	/*

    * property modal handlelick

  */

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("navbar"));
		setCreate(true);
		setOpenPostMenu(false);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	/*

    * Service modal handlelick

  */

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("navbar"));
		setOpenService(true);
		setOpenPostMenu(false);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	/*

    * Business Profile picture

  */

	const [businessProfilePicture, setBusinessProfilePicture] = React.useState(
		profile_context
			? profile_context.is_business_profile
				? profile_context.business_profile_image
					? profile_context.business_profile_image.exists
						? profile_context.business_profile_image.file_id
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg",
	);

	React.useEffect(() => {
		setBusinessProfilePicture(
			profile_context
				? profile_context.is_business_profile
					? profile_context.business_profile_image
						? profile_context.business_profile_image.exists
							? profile_context.business_profile_image.file_id
							: "/images/icons/Avatar.svg"
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg",
		);
	}, [profile_context]);

	/*

    * User Profile picture

  */

	const profilePicture = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.profile_picture
					? (session.user.dbUser.profile_picture.exists as boolean)
						? session.user.dbUser.profile_picture.file_id
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg"
		: "/images/icons/Avatar.svg";

	/*

    * using router to check pathname and pass default value when the page loads

  	*/

	const router = useRouter();

	const pathname = React.useRef(router).current;

	/*

    * Pooper for create modal

  	*/

	// const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
	//   null
	// );
	// const [open, setOpen] = React.useState(false);

	/*

    * Slug state to redirect to particular page

  */

	const Slug: any = session?.user?.dbUser?.pages
		? session.user.dbUser.pages.find((item: any) => item.page_id === profile_context?.business_profile_id)?.page_slug
		: null;

	/*

    * View Post Redirect

  */
	// const UrlRedirect =
	//   session === null
	//     ? "/auth/login"
	//     : profile_context?.page_type === "user"
	//       ? `users/${session?.user?.dbUser?._id}`
	//       : `/${profile_context?.page_type}s/${Slug}`;

	/*

    * Saved tab redirect

  */

	const bookmarkUrl =
		session === null
			? "/auth/login"
			: profile_context?.page_type === "user"
				? process.env.PRODUCTION_URL + `${router.locale}/users/${session?.user?.dbUser?._id}?tab=3`
				: process.env.PRODUCTION_URL + `${router.locale}/${profile_context?.page_type}s/${Slug}?tab=3`;

	/*

    * checking for value when pageloads on specific url

  */

	const profilePath = `/${router.locale}/${profile_context?.page_type}s/${Slug}?tab=3`;

	const [value, setValue] = React.useState(
		router.asPath === "/" || router.asPath.includes("utm")
			? 0
			: router.asPath === "/search"
				? 1
				: router.asPath === profilePath
					? 3
					: undefined,
	);

	React.useEffect(() => {
		setValue(
			router.asPath === "/" || router.asPath.includes("utm")
				? 0
				: router.asPath === "/search"
					? 1
					: router.asPath === profilePath
						? 3
						: undefined,
		);
	}, [router.asPath, profilePath]);

	/*

    * ref to check if clicked outside the popper. passing it to a div

  */
	// const wrapperRef = useRef(null);
	// useOutsideAlerter(wrapperRef);

	React.useEffect(() => {
		session
			? session.user
				? session.user.dbUser
					? session.user.dbUser.profile_picture
						? (session.user.dbUser.profile_picture.exists as boolean)
							? session.user.dbUser.profile_picture.file_id
							: "/images/icons/Avatar.svg"
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg";
	}, [session, pathname.asPath, value]);

	const profilepicchecks = profile_context
		? profile_context.is_business_profile
			? businessProfilePicture
			: profilePicture
		: profilePicture;

	/* Loading backdrop */

	const [openBackDrop, setOpenBackDrop] = React.useState(false);
	const handleClose = () => {
		setOpenBackDrop(false);
	};
	const handleOpen = () => {
		setOpenBackDrop(true);
	};

	/* OFB modal */

	const [CreateNewOfbPropertyModals, setCreateNewOfbPropertyModals] = React.useState(false);

	const handleCreateNewOfbPropertyOpen = () => {
		dispatch(setCreateNewPropertyTransactionTypeThunk("ofb"));
		dispatch(setCreateNewPropertyCurrentPageThunk("navbar"));
		setCreateNewOfbPropertyModals(true);
		setOpenPostMenu(false);
	};
	const handleCreateNewOfbPropertyClose = () => {
		setCreateNewOfbPropertyModals(false);
	};

	/*

    * Post modal State and functions

  */

	// const [openPost, setOpenPost] = React.useState(false);
	// const handleOpenPost = () => {
	//   setOpenPost(true);
	// };
	// const handleClosePost = () => {
	//   setOpenPost(false);
	// };

	/*

    *Snackbar State and functions

  */

	// const [SnackBaropen, setOpenSnackBarOpen] = React.useState(false);

	// const handleSnackBarClose = () => {
	//   setOpenSnackBarOpen(false);
	// };

	// const snackFunction = () => {
	//   setOpenSnackBarOpen(true);
	// };

	/*

	* Handle click location

	*/

	const locationSelect: any = ["HSR Layout", "Sarjapura Road", "Koramangala", "BTM Layout", "Jayanagar"];
	const [SubCategoryChecked, setSubCategoryChecked] = React.useState<string>("");

	const handleClickLocationChips = (data: string) => {
		setSubCategoryChecked(data);
		let lat: any = null;
		let lng: any = null;
		if (data === "HSR Layout") {
			lat = 12.9121;
			lng = 77.6446;
		} else if (data === "Sarjapura Road") {
			lat = 12.854922;
			lng = 77.788116;
		} else if (data === "Koramangala") {
			lat = 12.9352;
			lng = 77.6245;
		} else if (data === "BTM Layout") {
			lat = 12.9166;
			lng = 77.6101;
		} else if (data === "Jayanagar") {
			lat = 12.9308;
			lng = 77.5839;
		}

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: "location",
				newOptions: [
					{
						id: "location",
						title: "Location",
						value: {
							title: data,
							coordinates: [lng, lat],
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: "location",
				isApplied: true,
			}),
		);
		if (router.asPath.includes("/search")) {
			router
				.push(
					{
						pathname: "/search",
					},
					"/search",
					{ locale: router.locale },
				)
				.then(() => {
					dispatch(
						setSearchAnimationDetailsThunk({
							StartAnimate: searchanimationsettings?.StartAnimate as boolean,
							TriggerSearch: true,
							ResetSearch: true,
							PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
							HomeAccordion: false,
							OpenLocationDrawer: false,
						}),
					);
				});
		} else {
			router
				.push(
					{
						pathname: "/search",
					},
					"/search",
					{ locale: router.locale },
				)
				.then(() => {
					dispatch(
						setSearchAnimationDetailsThunk({
							StartAnimate: true,
							TriggerSearch: true,
							ResetSearch: false,
							PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
							HomeAccordion: false,
							OpenLocationDrawer: false,
						}),
					);
				});
		}
		setOpenSearchLocation(false);
	};

	function SlideTransition(props: any) {
		return (
			<Slide
				{...props}
				direction="up"
			/>
		);
	}
	const [openSnackbarLocation, setopenSnackbarLocation] = React.useState(false);

	React.useEffect(() => {
		if (searchanimationsettings?.OpenLocationDrawer === true) {
			setOpenSearchLocation(true);
		} else {
			setOpenSearchLocation(false);
		}
	}, [searchanimationsettings]);

	// const SeachButtonFunction = () => {
	// 	if (!search_filter_state?.location?.is_applied) {
	// 		setopenSnackbarLocation(true);
	// 	}

	// 	if (search_filter_state?.location?.is_applied) {
	// 		setopenSnackbarLocation(false);
	// 		setOpenSearchLocation(false);
	// 		if (router.asPath.includes("/search")) {
	// 			router
	// 				.push(
	// 					{
	// 						pathname: "/search",
	// 					},
	// 					"",
	// 					{ locale: router.locale },
	// 				)
	// 				.then(() => {
	// 					dispatch(
	// 						setSearchAnimationDetailsThunk({
	// 							StartAnimate: searchanimationsettings?.StartAnimate as boolean,
	// 							TriggerSearch: true,
	// 							ResetSearch: true,
	// 							PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
	// 							HomeAccordion: false,
	// 							OpenLocationDrawer: false,
	// 						}),
	// 					);
	// 				});
	// 		} else {
	// 			router
	// 				.push(
	// 					{
	// 						pathname: "/search",
	// 					},
	// 					"",
	// 					{ locale: router.locale },
	// 				)
	// 				.then(() => {
	// 					dispatch(
	// 						setSearchAnimationDetailsThunk({
	// 							StartAnimate: true,
	// 							TriggerSearch: true,
	// 							ResetSearch: false,
	// 							PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
	// 							HomeAccordion: false,
	// 							OpenLocationDrawer: false,
	// 						}),
	// 					);
	// 				});
	// 		}
	// 	}
	// };

	React.useEffect(() => {
		if (router.asPath.includes("create_property=true") && session !== null) {
			handleCreateOpen();
		}
		if (router.asPath.includes("create_service=true") && session !== null) {
			handleOpenNewService();
		}
		if (router.asPath.includes("create_ofb=true") && session !== null) {
			handleCreateNewOfbPropertyOpen();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			{/* Loading Icon */}

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={openBackDrop}
				onClick={handleClose}
			>
				<CustomSvgIcon
					inheritViewBox={false}
					viewBox="0 0 256 256"
					width={80}
					height={80}
				>
					<LoadingIcon />
				</CustomSvgIcon>
			</Backdrop>

			{/* Profile context snackbar */}

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openProfileContextSnackbar}
				autoHideDuration={3000}
				onClose={() => {
					setOpenProfileContextSnackbar(false);
				}}
				sx={{
					borderRadius: "0.5rem",
				}}
			>
				<Alert
					onClose={() => {
						setOpenProfileContextSnackbar(false);
					}}
					severity="success"
					sx={{ width: "100%" }}
				>
					{"Switched to " +
						(profile_context?.is_business_profile
							? businessProfiles.find((item: any) => item.page_id === profile_context?.business_profile_id)?.page_title
							: userName)}
				</Alert>
			</Snackbar>

			{/* Create Business Profile Modal */}

			<CreateBusinessModal
				open={openBusinessProfileModal}
				onClose={handleCloseBusinessProfile}
				session={session}
				dispatch={dispatch}
				new_business_profile_url={new_business_profile_url}
			/>

			{/* Create post modal */}

			{/* <PostImageUploadModal
				open={openPost}
				handleClose={handleClosePost}
				dispatch={dispatch}
				message={""}
				post={profile_context ? profile_context : ""}
				session={session}
				snackFunction={snackFunction}
				postId={postId}
			/> */}

			{/* Create property modal */}

			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={studio}
			/>

			{/* Create service modal */}

			<CreateServiceWithUploadTool
				open={openservice}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			{/* Create OFB modal */}

			<CreateNewOFBWithUploadTool
				open={CreateNewOfbPropertyModals}
				handleClose={handleCreateNewOfbPropertyClose}
				creator_user_id={profile_context ? profile_context.user_id : ""}
				business_profile_id={profile_context ? profile_context.business_profile_id : ""}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				profile_context={profile_context}
				session={session}
				newPropertyData={newPropertyData}
			/>

			{/* Profiles Drawer */}

			<CustomSwipeableDrawer
				anchor="bottom"
				open={openProfiles}
				onClose={() => setOpenProfiles(false)}
				onOpen={() => setOpenProfiles(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						background: theme.palette.background.paper,
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					},
				}}
			>
				<Puller />

				<Typography
					variant="h6"
					sx={{ margin: router.locale === "ar-AE" ? "1rem 1rem 0rem 0rem" : "1rem 0rem 0rem 0rem" }}
				>
					{router.locale === "ar-AE"
						? "ملفاتك الشخصية"
						: router.locale === "kn-IN"
							? "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ಗಳು"
							: "Your profiles"}
				</Typography>

				<DrawerCard>
					{!loading ? (
						<ContentContainer>
							<ProfilesContainer>
								<RadioGroup
									aria-labelledby="your-profiles"
									name="your-profiles"
									sx={{
										width: "100%",
									}}
									value={
										profile_context
											? profile_context.is_business_profile
												? profile_context.business_profile_id
												: profile_context.user_id
											: ""
									}
									onChange={(e) => {
										onChangeProfileContext(e.target.value);
									}}
								>
									<RadioWithLink>
										<CustomFormControlLabel
											value={session_user._id}
											labelPlacement="start"
											control={<Radio size="small" />}
											sx={{
												padding: router.locale === "ar-AE" ? "0rem 0rem 0rem 1rem" : "0rem 1rem 0rem 0rem",
											}}
											label={
												<ProfileCardLink
													href={"/" + router.locale + "/users/" + (session_user.slug || session_user._id)}
													rel="noopener"
													target="_self"
													referrerPolicy="no-referrer"
												>
													{!profilePicture.includes("/images/icons/Avatar.svg") ? (
														<ProfilePicture
															src={`/profile-picture/${profilePicture}`}
															alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
															referrerPolicy="no-referrer"
															loading="lazy"
														/>
													) : (
														<Initials
															title={
																session ? session.user.dbUser.first_name + " " + session.user.dbUser.last_name : ""
															}
															profiledropdown={false}
															header={false}
															comment={false}
															postcard={false}
															businessprofile={false}
															profilesdrawer={true}
														/>
													)}
													{/* <ProfilePicture
														src={
															profile_context
																? profile_context.is_business_profile
																	? businessProfilePicture
																	: profilePicture
																: profilePicture
														}
														alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
														loading="lazy"
														referrerPolicy="no-referrer"
													/> */}
													<NameTypeContainer
														sx={{
															margin: router.locale === "ar-AE" ? "0rem 0.75rem 0rem 0rem" : "0rem 0rem 0rem 0.75rem",
														}}
													>
														<Heading sx={{ width: "100%" }}>{userName}</Heading>
														<TypeTypo>
															{router.locale === "ar-AE"
																? "الملف الشخصي للمستخدم"
																: router.locale === "kn-IN"
																	? "ಬಳಕೆದಾರರ ಪ್ರೊಫೈಲ್"
																	: "User profile"}
														</TypeTypo>
													</NameTypeContainer>
												</ProfileCardLink>
											}
										/>
									</RadioWithLink>

									{businessProfiles.slice(0, 1).map((page: any, index: number) => (
										<RadioWithLink key={index}>
											<CustomFormControlLabel
												value={page.page_id}
												control={<Radio size="small" />}
												sx={{
													padding: router.locale === "ar-AE" ? "0rem 0rem 0rem 1rem" : "0rem 1rem 0rem 0rem",
												}}
												label={
													<ProfileCardLink
														href={"/" + router.locale + "/" + page.page_type + "s" + "/" + page.page_slug}
														rel="noopener"
														target="_self"
														referrerPolicy="no-referrer"
													>
														{page.display_picture_url.exists ? (
															<ProfilePicture
																src={`/profile-picture/${page.display_picture_url.file_id}`}
																alt={page.page_title.toUpperCase()}
																referrerPolicy="no-referrer"
																loading="lazy"
															/>
														) : (
															<Initials
																title={page.page_title}
																profiledropdown={false}
																header={false}
																comment={false}
																postcard={false}
																businessprofile={false}
																profilesdrawer={true}
															/>
														)}

														<NameTypeContainer
															sx={{
																margin: router.locale === "ar-AE" ? "0rem 0.75rem 0rem 0rem" : "0rem 0rem 0rem 0.75rem",
															}}
														>
															<Heading sx={{ width: "100%" }}>{page.page_title}</Heading>
															<TypeTypo>
																{page.page_type === "agent"
																	? router.locale === "ar-AE"
																		? "وكلاء العقارات"
																		: router.locale === "kn-IN"
																			? "ಏಜೆಂಟ್"
																			: "Agent"
																	: page.page_type === "developer"
																		? router.locale === "ar-AE"
																			? "المطور"
																			: router.locale === "kn-IN"
																				? "ಡೆವಲಪರ್"
																				: "Developer"
																		: page.page_type === "landowner"
																			? router.locale === "ar-AE"
																				? "مالك الأرض"
																				: router.locale === "kn-IN"
																					? "ಭೂಮಿಯ ಮಾಲಿಕ"
																					: "Landowner"
																			: page.page_type === "professional"
																				? router.locale === "ar-AE"
																					? "محترف الخدمة"
																					: router.locale === "kn-IN"
																						? "ಪ್ರೊಫೆಶನಲ್"
																						: "Service Professional"
																				: page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}
															</TypeTypo>
														</NameTypeContainer>
													</ProfileCardLink>
												}
											/>
										</RadioWithLink>
									))}
								</RadioGroup>
							</ProfilesContainer>
						</ContentContainer>
					) : (
						<LoadingContainer>
							<CircularProgress />
						</LoadingContainer>
					)}
				</DrawerCard>
			</CustomSwipeableDrawer>

			{/* <CustomSwipeableDrawer
				anchor="bottom"
				open={openProfiles}
				onClose={() => setOpenProfiles(false)}
				onOpen={() => setOpenProfiles(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<Puller />

				<Typography
					variant="h6"
					sx={{ margin: "1rem 0rem 0rem 0rem" }}
				>
					Your profiles
				</Typography>

				<DrawerCard>
					{!loading ? (
						<ContentContainer>
							<ProfilesContainer>
								<RadioGroup
									aria-labelledby="your-profiles"
									name="your-profiles"
									sx={{
										width: "100%",
									}}
									value={
										profile_context
											? profile_context.is_business_profile
												? profile_context.business_profile_id
												: profile_context.user_id
											: ""
									}
									onChange={(e) => {
										onChangeProfileContext(e.target.value);
									}}
								>
									<RadioWithLink>
										<CustomFormControlLabel
											value={session_user._id}
											labelPlacement="start"
											control={<Radio size="small" />}
											label={
												<ProfileCardLink
													href={"/users/" + (session_user.slug || session_user._id)}
													rel="noopener"
													target="_self"
													referrerPolicy="no-referrer"
												>
													{!profilePicture.includes("/images/icons/Avatar.svg") ? (
														<ProfilePicture
															src={`/profile-picture/${profilePicture}`}
															alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
															referrerPolicy="no-referrer"
															loading="lazy"
														/>
													) : (
														<Initials
															title={
																session ? session.user.dbUser.first_name + " " + session.user.dbUser.last_name : ""
															}
															profiledropdown={false}
															header={false}
															comment={false}
															postcard={false}
															businessprofile={false}
															profilesdrawer={true}
														/>
													)}
													{/* <ProfilePicture
														src={
															profile_context
																? profile_context.is_business_profile
																	? businessProfilePicture
																	: profilePicture
																: profilePicture
														}
														alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
														loading="lazy"
														referrerPolicy="no-referrer"
													/>
													<NameTypeContainer>
														<Heading sx={{ width: "100%" }}>{userName}</Heading>
														<TypeTypo>User</TypeTypo>
													</NameTypeContainer>
												</ProfileCardLink>
											}
										/>
									</RadioWithLink>

									{businessProfiles.map((page: any, index: number) => (
										<RadioWithLink key={index}>
											<CustomFormControlLabel
												value={page.page_id}
												control={<Radio size="small" />}
												label={
													<ProfileCardLink
														href={"/" + page.page_type + "s" + "/" + page.page_slug}
														rel="noopener"
														target="_self"
														referrerPolicy="no-referrer"
													>
														{page.display_picture_url.exists ? (
															<ProfilePicture
																src={`/profile-picture/${page.display_picture_url.file_id}`}
																alt={page.page_title.toUpperCase()}
																referrerPolicy="no-referrer"
																loading="lazy"
															/>
														) : (
															<Initials
																title={page.page_title}
																profiledropdown={false}
																header={false}
																comment={false}
																postcard={false}
																businessprofile={false}
																profilesdrawer={true}
															/>
														)}
														{/* <ProfilePicture
															src={
																page.display_picture_url !==
																process.env.CDN_URL + "images/uploads/placeholder_1080p.jpg/512/512"
																	? page.display_picture_url
																	: process.env.CDN_URL + "images/icons/Avatar.svg"
															}
															alt={page.page_title.toUpperCase()}
															loading="lazy"
															referrerPolicy="no-referrer"
														/>
														<NameTypeContainer>
															<Heading sx={{ width: "100%" }}>{page.page_title}</Heading>
															<TypeTypo>{page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}</TypeTypo>
														</NameTypeContainer>
													</ProfileCardLink>
												}
											/>
										</RadioWithLink>
									))}
								</RadioGroup>
							</ProfilesContainer>

							{businessProfiles.length < businessProfilesCount ? (
								<ContentRow
									onClick={handleClickShowMoreBusinessProfiles}
									sx={{
										padding: "0.75rem 0rem 0rem 0rem",
									}}
								>
									<UnfoldMoreOutlined
										color="primary"
										sx={{
											margin: "0rem 0.75rem 0rem 0.75rem",
											fontSize: "1.5rem",
										}}
									/>
									<Typography
										variant="body1"
										sx={{
											color: theme.palette.primary.main,
										}}
									>
										Show more
									</Typography>
								</ContentRow>
							) : (
								<ContentRow
									onClick={handleOpenBusinessProfile}
									sx={{
										padding: "0.75rem 0rem 0rem 0rem",
									}}
								>
									<PersonAddAltOutlined
										color="primary"
										sx={{
											margin: "0rem 0.75rem 0rem 0.75rem",
											fontSize: "1.5rem",
										}}
									/>
									<Typography
										variant="body1"
										sx={{
											color: theme.palette.mode == "dark" ? "#ffffff" : "#000000",
										}}
									>
										Add business profile
									</Typography>
								</ContentRow>
							)}
						</ContentContainer>
					) : (
						<LoadingContainer>
							<CircularProgress />
						</LoadingContainer>
					)}
				</DrawerCard>
			</CustomSwipeableDrawer> */}

			{/* Post Drawer */}

			<CustomSwipeableDrawer
				anchor="bottom"
				open={openPostMenu}
				onClose={() => setOpenPostMenu(false)}
				onOpen={() => setOpenPostMenu(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						background: theme.palette.background.paper,
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					},
				}}
			>
				<Puller />

				<CustomLink
					href={"/" + router.locale + "/intro"}
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
					sx={{
						fontWeight: router.locale === "ar-AE" ? 600 : 500,
					}}
				>
					{router.locale === "ar-AE"
						? "ندخل في العقارات"
						: router.locale === "kn-IN"
							? "ರಿಯಲ್ ಎಸ್ಟೇಟ್‌ಗೆ ಪ್ರವೇಶಿಸಿ"
							: "Get into real estate"}
				</CustomLink>

				<ContentContainer
					sx={{
						padding: router.locale === "ar-AE" ? "0rem 0.5rem 0.75rem 0rem" : "0rem 0rem 0.75rem 0.5rem",
						gap: "1rem",
					}}
				>
					<ContentRow
						onClick={() => {
							session !== null
								? handleCreateOpen()
								: router
										.push(
											{
												pathname: encodeURI("/?create_property=true"),
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						{/* <AddHomeWorkOutlined
							color="primary"
							sx={{
								margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
								fontSize: "2.5rem",
							}}
						/> */}
						<SvgIcon
							component={PostProperty}
							inheritViewBox={false}
							viewBox="0 0 37 40"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE"
									? "انشر عقارًا"
									: router.locale === "kn-IN"
										? "ಆಸ್ತಿಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
										: "Post property"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "بع أو أجر عقارك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ"
										: "Sell or rent out your property"}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null
								? handleOpenNewService()
								: router
										.push(
											{
												pathname: encodeURI("/?create_service=true"),
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						{/* <HomeRepairServiceOutlined
							color="primary"
							sx={{
								margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
								fontSize: "2.5rem",
							}}
						/> */}
						<SvgIcon
							component={PostService}
							inheritViewBox={false}
							viewBox="0 0 36 42"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE"
									? "انشر خدمة"
									: router.locale === "kn-IN"
										? "ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
										: "Post service"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "ابحث عن عملاء لخدمتك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಸೇವೆಗಾಗಿ ಗ್ರಾಹಕರನ್ನು ಹುಡುಕಿ"
										: "Find clients for your service"}{" "}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null
								? handleCreateNewOfbPropertyOpen()
								: router
										.push(
											{
												pathname: encodeURI("/?create_ofb=true"),
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						{/* <AddBusinessOutlined
							color="primary"
							sx={{
								margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
								fontSize: "2.5rem",
							}}
						/> */}
						<SvgIcon
							component={PostOFB}
							inheritViewBox={false}
							viewBox="0 0 42 41"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE" ? "انشر أو إف بي" : router.locale === "kn-IN" ? "ಪೋಸ್ಟ್ OFB" : "Post OFB"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "استفد من المواقع لصالحك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕಾಗಿ ಸ್ಥಳಗಳನ್ನು ಬಳಸಿಕೊಳ್ಳಿ"
										: "Utilize locations to your benefit"}{" "}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					{/* <ContentRow
						onClick={() => {
							session !== null ? handleOpenBusinessProfile() : signIn();
						}}
					>
						<PersonAddAltOutlined
							color="primary"
							sx={{
								margin: "0rem 0.5rem 0rem 0rem",
								fontSize: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading>Create business profile</Heading>
							<TypeTypo>Post your lisitngs as a business</TypeTypo>
						</NameTypeContainer>
					</ContentRow> */}
				</ContentContainer>
			</CustomSwipeableDrawer>

			{/* <div
        ref={wrapperRef}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      > */}

			{/* <Snackbar
          open={SnackBaropen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          sx={{
            "& .MuiSnackbarContent-root": {
              background: theme.palette.mode == "dark" ? "#212B36" : "#FFFFFF",
              color: theme.palet<LocationAutoCompleteMobile
						search_id="location"
						dispatch={dispatch}
						search_filter_state={search_filter_state?.location as StateInterface["search_filters_state"]["location"]}
					/>te.mode == "dark" ? "#FFFFFF" : "#212B36",
            },
          }}
        >
          <Alert
            onClose={handleSnackBarClose}
            severity="success"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              background: theme.palette.mode == "dark" ? "#4E9A51" : "#4E9A51",
              color: theme.palette.mode == "dark" ? "#FFFFFF" : "#FFFFFF",
            }}
          >
            <Typography variant="body1" component="p">
              Post uploaded successfully!
              <BeegruButton
                flavor="primary"
                variant="contained"
                size="small"
                href={UrlRedirect}
                name="View Post"
                type="button"
                sx={{
                  marginLeft: "0.15rem",
                }}
              >
                View post
              </BeegruButton>
            </Typography>
          </Alert>
        </Snackbar> */}

			{/* Search location drawer */}

			<CustomSwipeableDrawer
				anchor="bottom"
				open={openSearchLocation}
				onClose={() => setOpenSearchLocation(false)}
				onOpen={() => setOpenSearchLocation(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						background: theme.palette.background.paper,
						padding: "1rem",
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					},
				}}
			>
				<Snackbar
					open={openSnackbarLocation}
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					sx={{
						bottom: "70px",
					}}
					TransitionComponent={SlideTransition}
				>
					<SnackbarContent
						sx={{
							borderRadius: "0.85rem",
							color: "#5F2B01",
							background: "#FDF0E5",
							boxShadow: "none",
							display: "flex",
							alignItems: "center",
							fontWeight: 500,
							fontSize: "1rem",
						}}
						message={
							<span style={{ display: "flex", alignItems: "center" }}>
								<WarningAmber style={{ marginRight: "0.75rem" }} />
								{router.locale === "ar-AE"
									? "يرجى إدخال الموقع."
									: router.locale === "kn-IN"
										? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
										: "Please enter a location"}
							</span>
						}
					/>
				</Snackbar>

				<ContentContainer
					sx={{
						gap: "0.5rem",
					}}
				>
					<Typography variant="subtitle2">
						{router.locale === "ar-AE" ? "أدخل موقع البحث" : "Enter search location"}
					</Typography>
					<LocationAutoCompleteMobile
						search_id="location"
						dispatch={dispatch}
						search_filter_state={search_filter_state.location as StateInterface["search_filters_state"]["location"]}
					/>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							gap: "8px",
							width: "100%",
							margin: "0.5rem 0rem 0.5rem 0rem",
						}}
					>
						<Divider style={{ flex: 1 }} />
						<span style={{ padding: "0 8px", fontSize: "1rem", color: "#333" }}>
							{router.locale === "ar-AE" ? "أو" : "OR"}
						</span>
						<Divider style={{ flex: 1 }} />
					</div>

					<Stack
						sx={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: "0.5rem",
							"&::-webkit-scrollbar": {
								display: "none",
							},
						}}
					>
						<Typography
							variant="body2"
							sx={{ padding: "0rem 0rem 0rem 0rem", fontWeight: 500, width: "100%" }}
						>
							{router.locale === "ar-AE"
								? "يرجى إدخال الموقع"
								: router.locale === "kn-IN"
									? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
									: "Please enter a location"}
						</Typography>

						{locationSelect?.map((data: any, id: any) => (
							<LocationChips
								key={id}
								sx={{
									backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
									color:
										theme.palette.mode === "dark"
											? SubCategoryChecked === data
												? "#ffffff"
												: "rgba(255, 255, 255, 1)"
											: SubCategoryChecked === data
												? "rgba(98, 56, 22, 1)"
												: "black",
									border:
										theme.palette.mode === "dark"
											? SubCategoryChecked === data
												? "1px solid transparent"
												: "1px solid rgba(255, 255, 255, 0.23)"
											: SubCategoryChecked === data
												? "1px solid transparent"
												: "1px solid rgba(0, 0, 0, 0.23)",
									"&.MuiChip-clickable:hover": {
										backgroundColor: SubCategoryChecked === data ? "#FC801929" : "transparent",
									},
								}}
								label={data}
								clickable
								variant="outlined"
								// avatar={<AddIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />}
								onClick={() => {
									SubCategoryChecked === data ? handleClickLocationChips(data) : handleClickLocationChips(data);
								}}
							/>
						))}
					</Stack>

					{/* <BeegruButton
						flavor="primary"
						variant="contained"
						size="medium"
						sx={{ fontSize: "1rem", alignItems: "center", width: "100%", margin: "1rem 0rem 0rem 0rem" }}
						onClick={() => {
							search_filter_state?.location?.is_applied ? SeachButtonFunction() : null;
						}}
					>
						Search
					</BeegruButton> */}
				</ContentContainer>
			</CustomSwipeableDrawer>

			{/* Bottom Navigation */}

			<CustomBottomNavigation
				showLabels
				value={value}
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "الرئيسية" : router.locale === "kn-IN" ? "ಮುಖಪುಟ" : "Home"}
					disableRipple
					icon={
						value === 0 ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<Home
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<HomeOutlined fontSize="small" />
						)
					}
					href={"/" + router.locale}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0.5rem 0rem 0rem 0rem",
						},
					}}
				/>

				{/*

					* Feed tab Commented out
				*/}

				{/* <BottomNavigationAction
					label="Feed"
					disableRipple
					icon={
						value === 1 ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#FECCA3",
									borderRadius: "1rem",
								}}
							>
								<Feed
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#B05A12" }}
								/>{" "}
							</div>
						) : (
							<FeedOutlined fontSize="small" />
						)
					}
					href="/Feed"
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0.45rem 0rem 0rem 0rem",
						},
					}}
				/> */}
				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "بحث" : router.locale === "kn-IN" ? "ಹುಡುಕಿ" : "Search"}
					disableRipple
					icon={
						value === 1 ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<Search
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<SearchOutlined fontSize="small" />
						)
					}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0.45rem 0rem 0rem 0rem",
						},
					}}
					onClick={() => {
						{
							searchLocation
								? router
										.push(
											{
												pathname: "/search",
											},
											"/search",
											{ locale: router.locale },
										)
										.then(() => {
											dispatch(
												updateSearchFilterSelectedOptionsThunk({
													searchFilterId: "location",
													newOptions: [
														{
															id: "location",
															title: "Location",
															value: {
																title: searchLocation.value.title,
																coordinates: searchLocation.value.coordinates,
															},
														},
													],
												}),
											);
											dispatch(
												updateSearchFilterAppliedThunk({
													searchFilterId: "location",
													isApplied: true,
												}),
											);
											dispatch(
												setSearchAnimationDetailsThunk({
													StartAnimate: true,
													TriggerSearch: true,
													ResetSearch: false,
													PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
													HomeAccordion: false,
													OpenLocationDrawer: false,
												}),
											);
										})
								: setOpenSearchLocation(!openSearchLocation);
						}
					}}
				/>
				<BottomNavigationAction
					// label="Post"
					icon={<AddCircle sx={{ fontSize: "3.5rem", color: "#1BA672" }} />}
					onClick={() => setOpenPostMenu(!openPostMenu)}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
						},
					}}
				/>

				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "المحفوظة" : router.locale === "kn-IN" ? "ಉಳಿಸಲಾಗಿದೆ" : "Saved"}
					disableRipple
					icon={
						value === 3 ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<BookmarkBorder
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<BookmarkBorderOutlined fontSize="small" />
						)
					}
					href={`${bookmarkUrl}`}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0.45rem 0rem 0rem 0rem",
						},
					}}
				/>

				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "الملفات الشخصية" : router.locale === "kn-IN" ? "ಪ್ರೊಫೈಲ್ಗಳು" : "Profiles"}
					disableRipple
					onClick={() => {
						session !== null ? setOpenProfiles(!openProfiles) : signIn();
					}}
					icon={
						!profilepicchecks.includes("/images/icons/Avatar.svg") ? (
							<ProfilePicture
								src={`/profile-picture/${
									profile_context
										? profile_context.is_business_profile
											? businessProfilePicture
											: profilePicture
										: profilePicture
								}`}
								alt="user"
								referrerPolicy="no-referrer"
								loading="lazy"
								sx={{
									width: 28,
									height: 28,
									margin: "0rem 0rem 0rem 0rem",
									borderRadius: "8px",
								}}
							/>
						) : (
							<Initials
								title={
									profile_context ? (profile_context.is_business_profile ? profile_context.page_title : userName) : ""
								}
								profiledropdown={false}
								header={false}
								comment={false}
								postcard={false}
								businessprofile={false}
								bottomnav={true}
							/>
						)
					}
					// <ProfilePicture
					// 	src={
					// 		profile_context
					// 			? profile_context.is_business_profile
					// 				? businessProfilePicture
					// 				: profilePicture
					// 			: profilePicture
					// 	}
					// 	alt="Profile Picture"
					// 	loading="lazy"
					// 	referrerPolicy="no-referrer"
					// 	sx={{
					// 		width: 28,
					// 		height: 28,
					// 		margin: "0rem 0rem 0rem 0rem",
					// 		borderRadius: "8px",
					// 	}}
					// />

					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
						},
					}}
				/>
			</CustomBottomNavigation>
		</React.Fragment>
	);
};

export default BottomNavigate;
