/* Common Imports */

import React from "react";

/* Component Imports */

import { Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/system";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.625rem",
	lineHeight: "0.75rem",
	margin: "0.215rem 0rem 0.215rem 0rem",
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.7rem",
		lineHeight: "0.75rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		fontSize: "0.625rem",
		lineHeight: "0.75rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.75rem",
		lineHeight: "0.85rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.75rem",
		lineHeight: "0.85rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.825rem",
		lineHeight: "1rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.875rem",
		lineHeight: "1rem",
		margin: "0.215rem 0rem 0.25rem 0rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
		lineHeight: "1.15rem",
	},
}));

const GridCardTitle = ({ loading, title }: { loading?: boolean; title?: string }) => {
	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
			/>
		);
	}

	return (
		<React.Fragment>
			<Heading variant="body1">{title ? (title.length < 14 ? title : title?.substring(0, 14) + "...") : ""}</Heading>
		</React.Fragment>
	);
};

export default GridCardTitle;
