/* Common Imports */

import { styled } from "@mui/system";

/* Redux Imports */

/* Component Imports */

import { Skeleton, Typography, useTheme } from "@mui/material";
import BeegruButton from "../../../../common-components/buttons/BeegruButton";

/* Icon Imports */

import { CorporateFareOutlined } from "@mui/icons-material";
import StateInterface from "../../../../../redux-magic/state-interface";
import { SetPropertyBoostModalStateThunk } from "../../../../../redux-magic/store";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "100%",
	gap: "0.125rem",
	margin: "0rem 0rem 0rem 0rem",
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.25rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.625rem",
	lineHeight: "0.725rem",
	color: theme.palette.text.secondary,
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.7rem",
		lineHeight: "0.75rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		fontSize: "0.625rem",
		lineHeight: "0.725rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.75rem",
		lineHeight: "0.75rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.75rem",
		lineHeight: "0.85rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
	},
}));

const CustomIcon = styled(CorporateFareOutlined)(({ theme }) => ({
	color: theme.palette.text.secondary,
	margin: "0rem 0rem 0rem 0rem",
	fontSize: "0.625rem",
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.7rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		fontSize: "0.625rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.75rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.75rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.825rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
	},
}));

const PropertyCardCTA = ({
	loading,
	developer,
	config,
	card_title,
	published_status,
	dispatch,
	session,
	slug,
	title,
	created_by,
	show_contact_details,
	isEditor,
	isBoosted,
	bottom_beegru_points,
	boost,
}: {
	loading: boolean;
	developer: string;
	config: string | null | undefined;
	card_title: string | undefined;
	published_status: string | undefined;
	dispatch: Function;
	session: any;
	slug: string;
	title: string;
	created_by: any;
	show_contact_details: boolean;
	isEditor: boolean | undefined;
	isBoosted: boolean;
	bottom_beegru_points?: StateInterface["bottom_subscribe_buy_beegru_points"];
	boost?: StateInterface["boost"];
}) => {
	const theme = useTheme();

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
				style={{ marginBottom: "0.5rem" }}
			/>
		);
	} else {
		return isEditor ? (
			/* Editor View */

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "100%",
				}}
			>
				{published_status === "published" ? (
					isBoosted && boost && boost.active ? (
						<BeegruButton
							variant="outlined"
							flavor="boost"
							sx={{
								height: "2rem",
								width: "100%",
								borderRadius: "8px",
								fontSize: "0.625rem",
								fontWeight: 500,
								/*iphone XR - 414*/
								[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
									fontSize: "0.7rem",
								},
								[theme.breakpoints.up("xsPlus")]: {
									fontSize: "0.625rem",
								},
								/*684*/
								[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
									fontSize: "0.75rem",
								},
								/*ipad Mini */
								[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
									fontSize: "0.75rem",
								},
								/* ipad Air*/
								[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
									fontSize: "0.825rem",
								},
								/* 860*/
								[theme.breakpoints.up("sm860")]: {
									fontSize: "0.875rem",
								},
								/*910*/
								[theme.breakpoints.up("sm910")]: {
									fontSize: "0.95rem",
								},
							}}
							onClick={(event: any) => {
								event.preventDefault();
								dispatch(SetPropertyBoostModalStateThunk({ state: true, id: slug }));
							}}
							// onClick={
							// dispatch(SetPropertyBoostModalStateThunk({ state: true, id: slug }));
							// }
						>
							Boosted
						</BeegruButton>
					) : (
						<BeegruButton
							flavor="primary"
							variant="contained"
							sx={{
								height: "2rem",
								width: "100%",
								borderRadius: "8px",
								fontSize: "0.625rem",
								fontWeight: 500,
								/*iphone XR - 414*/
								[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
									fontSize: "0.7rem",
								},
								[theme.breakpoints.up("xsPlus")]: {
									fontSize: "0.625rem",
								},
								/*684*/
								[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
									fontSize: "0.75rem",
								},
								/*ipad Mini */
								[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
									fontSize: "0.75rem",
								},
								/* ipad Air*/
								[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
									fontSize: "0.825rem",
								},
								/* 860*/
								[theme.breakpoints.up("sm860")]: {
									fontSize: "0.875rem",
								},
								/*910*/
								[theme.breakpoints.up("sm910")]: {
									fontSize: "0.95rem",
								},
							}}
							// onClick={(event: any) => {
							// 	event.preventDefault();
							// 	// 	session === null ? window.open("/auth/login") : submit(false);
							// }}
							onClick={(event: any) => {
								event.preventDefault();
								dispatch(SetPropertyBoostModalStateThunk({ state: true, id: slug }));
							}}
						>
							{/*  untill service boost is not done we will keep it as published */}
							Published
							{/* Boost */}
						</BeegruButton>
					)
				) : published_status === "publish_requested" ? (
					<BeegruButton
						flavor="boost"
						variant="outlined"
						sx={{
							height: "2rem",
							width: "100%",
							borderRadius: "8px",
							fontSize: "0.625rem",
							fontWeight: 500,
							paddingLeft: "0.5rem",
							paddingRight: "0.5rem",
							/*iphone XR - 414*/
							[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
								fontSize: "0.7rem",
							},
							[theme.breakpoints.up("xsPlus")]: {
								fontSize: "0.625rem",
							},
							/*684*/
							[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
								fontSize: "0.75rem",
							},
							/*ipad Mini */
							[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
								fontSize: "0.75rem",
							},
							/* ipad Air*/
							[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
								fontSize: "0.825rem",
							},
							/* 860*/
							[theme.breakpoints.up("sm860")]: {
								fontSize: "0.875rem",
							},
							/*910*/
							[theme.breakpoints.up("sm910")]: {
								fontSize: "0.95rem",
							},
						}}
						disabled
						// onClick={(event: any) => {
						// 	event.preventDefault();
						// 	session === null ? window.open("/auth/login") : submit(false);
						// }}
					>
						Under review
					</BeegruButton>
				) : (
					<BeegruButton
						flavor="primary"
						variant="outlined"
						sx={{
							height: "2rem",
							width: "100%",
							borderRadius: "10px",
							fontSize: "0.625rem",
							/*iphone XR - 414*/
							[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
								fontSize: "0.7rem",
							},
							[theme.breakpoints.up("xsPlus")]: {
								fontSize: "0.625rem",
							},
							/*684*/
							[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
								fontSize: "0.75rem",
							},
							/*ipad Mini */
							[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
								fontSize: "0.75rem",
							},
							/* ipad Air*/
							[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
								fontSize: "0.825rem",
							},
							/* 860*/
							[theme.breakpoints.up("sm860")]: {
								fontSize: "0.875rem",
							},
							/*910*/
							[theme.breakpoints.up("sm910")]: {
								fontSize: "0.95rem",
							},
						}}
					>
						Publish
					</BeegruButton>
				)}
			</div>
		) : null;
	}
};

export default PropertyCardCTA;
