/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { setCreateNewServiceDefaultLocationThunk } from "../../../../../redux-magic/thunks";

/* Component Imports */

import { Checkbox, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import usePlacesAutocomplete from "use-places-autocomplete";
import Search from "./SearchServiceLocation";

/*
&.Location Picker library imports

*/

const LocationPicker = dynamic<{
	containerElement: React.ReactNode;
	mapElement: React.ReactNode;
	onChange: Function;
	defaultPosition: object;
	zoom?: number;
	radius: number;
	circleOptions?: object;
	//@ts-ignore
}>(() => import("@beegru/react-location-picker"), {
	ssr: false,
});

/*
&.Mapbox imports

*/

mapboxgl.accessToken = process.env.MAPBOXGL_ACCESS_TOKEN || "";

/*

& Next, let's describe an interface to determine the shape of the body that we will send to the GecodeApi endpoint.

*/

interface GeolocationCoordinates {
	latitude: number;
	longitude: number;
	altitude: number | null;
	accuracy: number;
	altitudeAccuracy: number | null;
	heading: number | null;
	speed: number | null;
}

interface GeolocationPosition {
	coords: GeolocationCoordinates;
}

interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}

interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}

interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}

interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}

interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}

interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

type MapContainerProps = {
	showMap: boolean;
};

/* Styled Components */

const MapboxContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	borderRadius: "8px",
	padding: "2rem 0rem 0rem 0rem",
	width: "100%",
	background: "transparent",
	boxShadow: "none",
	gap: "1rem",
}));

const MapContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "showMap",
})<MapContainerProps>(({ theme, showMap }) => ({
	width: "100%",
	display: showMap ? undefined : "none",
	overflow: "hidden",
}));

const Map = styled("div")(({ theme }) => ({
	height: "20rem",
	overflow: "hidden",
	borderRadius: "1rem",
}));

const EditContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	padding: "0rem 0rem 0.25rem 0rem",
	width: "100%",
}));

const SearchContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.688rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
	lineHeight: "150%",
}));

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const ServiceLocation = ({
	newServiceDataLocationCoordinates,
	dispatch,
	showMap,
}: {
	dispatch: Function;
	newServiceDataLocationCoordinates: Array<number>;
	showMap: boolean;
}) => {
	const router = useRouter();
	const [fromList, setFromList] = React.useState(null);
	const [change, setChange] = React.useState<{ lat: number; lng: number }>({
		lat: newServiceDataLocationCoordinates[0],
		lng: newServiceDataLocationCoordinates[1],
	});

	/*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */
	/* State to check if map is loaded */

	const [address, setAddress] = React.useState<string>("");
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || "",
		libraries: librariesToLoad as any,
	});

	const {
		value,
		suggestions: { data },
		setValue,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});

	const [locaionText, setLocationText] = React.useState<string>("");
	const [helperText, setHelperText] = React.useState<string>("");
	const [selected_pin, setSelectedPin] = React.useState<boolean>(false);

	/*

  & let's get the location and pincode from the map and set it to the state

  */

	const handleLocationChange = async ({
		position,
		address,
	}: {
		position: { lat: number; lng: number };
		address: string;
	}) => {
		// setState({ position, address });
		setChange({ lat: position.lat, lng: position.lng });
		setHelperText("");
		setSelectedPin(true);
	};

	/*

  & let's use reverseGeocodeApi to get the locality and pincode from the coordinates and set it to the state

  */

	React.useEffect(() => {
		const executor: Function = async () => {
			const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
				await fetch(
					"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
						change.lat +
						"," +
						change.lng +
						"&key=" +
						(process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || ""),
					{ cache: "no-store" },
				)
			).json();
			const userCurrentAddresses: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
				(result) => {
					return result.address_components?.find(
						(address_component: GMRGCResultsAddressComponent) =>
							address_component.types.includes("sublocality_level_1") ||
							address_component.types.includes("postal_town") ||
							address_component.types.includes("locality"),
					)?.long_name as string;
				},
			);

			let userCurrentAddress: string | undefined;

			let resultPos: number = 0;

			for (let i = 0; i < userCurrentAddresses.length; i++) {
				if (userCurrentAddresses[i] !== "Bengaluru") {
					if (userCurrentAddresses[i] !== undefined) {
						userCurrentAddress = userCurrentAddresses[i];
						resultPos = i;
						break;
					} else {
						i++;
					}
				}
			}
			setAddress(userCurrentAddress || "");

			const userCurrentAddressPin: string | undefined = googleMapsReverseGeocodingApiResponse?.results[
				resultPos
			]?.address_components?.find(
				(address_component: GMRGCResultsAddressComponent) =>
					address_component.types.includes("postal_code") || address_component.types.includes("pincode"),
			)?.long_name;

			setValue(userCurrentAddressPin || "");
		};
		executor();
	}, [change, setValue]);

	const [checked, setChecked] = React.useState(false);

	const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		setHelperText("pin your location on the map");
	};

	React.useEffect(() => {
		if (selected_pin)
			dispatch(
				setCreateNewServiceDefaultLocationThunk({
					location_coordinates: [change.lat, change.lng],
					address: checked ? locaionText : address,
				}),
			);
	}, [change.lat, change.lng, dispatch, address, checked, locaionText, selected_pin]);

	return (
		<MapboxContainer id="map">
			<Heading>
				{router.locale === "kn-IN" ? "ನಿಮ್ಮ ಸೇವೆಯ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ" : "Enter the location for your service"}
			</Heading>
			<TextContainer>
				<EditContainer>
					<SearchContainer>
						{!checked ? (
							<Search
								change={checked ? locaionText : address}
								fromList={fromList}
								setFromList={setFromList}
								dispatch={dispatch}
							/>
						) : null}
						{checked ? (
							<TextField
								id="outlined-basic"
								label={
									helperText !== ""
										? router.locale === "kn-IN"
											? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಕ್ಷೆಯಲ್ಲಿ ಪಿನ್ ಮಾಡಿ"
											: "Pin your location on the map"
										: router.locale === "kn-IN"
											? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
											: "Enter your location"
								}
								variant="outlined"
								value={locaionText}
								error={helperText !== ""}
								helperText={helperText}
								onChange={(e) => {
									setLocationText(e.target.value);
								}}
								// disabled={helperText !== "" ? true : false}
								sx={{
									width: "100%",
									margin: "1rem 0rem 0rem 0rem",
								}}
								placeholder={
									helperText !== ""
										? router.locale === "kn-IN"
											? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಕ್ಷೆಯಲ್ಲಿ ಪಿನ್ ಮಾಡಿ"
											: "Pin your location on the map"
										: router.locale === "kn-IN"
											? "ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
											: "Enter your location"
								}
							/>
						) : null}
					</SearchContainer>
					<FormControlLabel
						control={
							<Checkbox
								checked={checked}
								onChange={handleChangeCheckbox}
							/>
						}
						label={router.locale === "kn-IN" ? "ಸ್ಥಳವನ್ನು ಕೈಯಾರೆ ನಮೂದಿಸಿ" : "Enter Location Manually"}
						sx={{ margin: "0rem 0rem 1rem 0rem" }}
					/>
					{isLoaded && (showMap || checked) && (
						<LocationPicker
							containerElement={<MapContainer showMap={true} />}
							mapElement={<Map />}
							defaultPosition={{
								lat: newServiceDataLocationCoordinates[0],
								lng: newServiceDataLocationCoordinates[1],
							}}
							radius={10}
							onChange={handleLocationChange}
						/>
					)}
				</EditContainer>
			</TextContainer>
		</MapboxContainer>
	);
};

export default ServiceLocation;
