/* Common Imports */

import { styled } from "@mui/system";
import { useRouter } from "next/router";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../redux-magic/state-interface";
import {
	deletePropertyDetailsThunk,
	deletePropertyOFBDetailsThunk,
	deleteServiceDetailsThunk,
} from "../../../redux-magic/store";

/* Component Imports */

import {
	// IconButton,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import BeegruButton from "../../common-components/buttons/BeegruButton";

/* Icon Imports */

// import { Close } from "@mui/icons-material";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	fontSize: "1.25rem",
	fontWeight: 400,
	background: theme.palette.background.paper,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "1.5rem",
	padding: "2rem 1rem 1rem 1rem",
	background: theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
	},
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "0rem 1rem 1rem 0rem",
	background: theme.palette.background.paper,
}));

const DeleteListingPropertyModal = ({
	creator_user_id,
	creator_business_profile_id,
	propertyId,
	open,
	handleClose,
	dispatch,
	isEditor,
	profile_context,
	session,
	property_type,
}: {
	creator_user_id: string | ObjectId;
	creator_business_profile_id: string | ObjectId;
	propertyId: string | ObjectId;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	isEditor: boolean;
	profile_context: StateInterface["profile_context"];
	session: any;
	property_type: string;
}) => {
	const [submitting, setSubmitting] = React.useState<boolean>(false);

	/*

  & Let's declare the router. Here we are using the useRouter hook from nextjs.

  & We are using the router to get the query params from the url. Memoize the router object to prevent unnecessary re-renders.

  */

	const router = React.useRef(useRouter()).current;

	const handleDelete = async () => {
		setSubmitting(true);
		if (property_type === "ofb") {
			await dispatch(
				deletePropertyOFBDetailsThunk({
					property_id: propertyId,
					is_business_profile: isEditor ? profile_context.is_business_profile : false,
					user_id: isEditor ? profile_context.user_id : creator_user_id,
					business_profile_id: isEditor ? profile_context.business_profile_id : creator_business_profile_id,
					sessionId: session ? (session.session_id ? session.session_id : "") : "",
					url: router.pathname ? router.pathname : "",
				}),
			);
			handleClose();
			setSubmitting(false);
		} else if (property_type === "service") {
			await dispatch(
				deleteServiceDetailsThunk({
					service_id: propertyId,
					is_business_profile: isEditor ? profile_context.is_business_profile : true,
					user_id: isEditor ? profile_context.user_id : creator_user_id,
					business_profile_id: isEditor ? profile_context.business_profile_id : creator_business_profile_id,
					sessionId: session ? (session.session_id ? session.session_id : "") : "",
					url: router.pathname ? router.pathname : "",
				}),
			);
		} else {
			await dispatch(
				deletePropertyDetailsThunk({
					property_id: propertyId,
					is_business_profile: isEditor ? profile_context.is_business_profile : true,
					user_id: isEditor ? profile_context.user_id : creator_user_id,
					business_profile_id: isEditor ? profile_context.business_profile_id : creator_business_profile_id,
					sessionId: session ? (session.session_id ? session.session_id : "") : "",
					url: router.pathname ? router.pathname : "",
				}),
			);
		}
		setSubmitting(false);
		handleClose();
	};

	return (
		<React.Fragment>
			<CustomDialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth={true}
				disableScrollLock={true}
			>
				<CustomDialogTitle>
					Are you sure you want to delete this {property_type}?
					{/* <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton> */}
				</CustomDialogTitle>

				<CustomDialogContent>
					<Typography variant="body2">
						This {property_type} will be deleted permanently and you cannot undo this action.
					</Typography>
				</CustomDialogContent>

				<CustomDialogActions>
					{!submitting ? (
						<React.Fragment>
							<BeegruButton
								flavor="primary"
								variant="outlined"
								onClick={handleClose}
								disabled={submitting}
								name="cancel"
								type="button"
							>
								Cancel
							</BeegruButton>

							<BeegruButton
								flavor="primary"
								variant="contained"
								disabled={submitting}
								onClick={() => {
									handleDelete();
								}}
								name="submit"
								type="button"
							>
								Delete
							</BeegruButton>
						</React.Fragment>
					) : (
						<CircularProgress />
					)}
				</CustomDialogActions>
			</CustomDialog>
		</React.Fragment>
	);
};

export default DeleteListingPropertyModal;
